export function searchByPayload(events: any[], filterText: string): any[] {
  const matchingEvents: any[] = [];
  const uniqueEventIds: Set<string> = new Set();
  events.forEach((event) => {
    if (searchObject(event, filterText) && !uniqueEventIds.has(event.id)) {
      matchingEvents.push(event);
      uniqueEventIds.add(event.id);
    }
  });
  return matchingEvents;
}

function searchObject(obj: any, filterText: string): boolean {
  const lowerCaseFilterText: string = filterText.toLowerCase();
  for (const key in obj) {
    const lowerKey: string = key.toLowerCase();
    if (lowerCaseFilterText === lowerKey) {
      return true;
    }
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      if (searchObject(obj[key], filterText)) {
        return true;
      }
    } else {
      if (
        lowerKey === lowerCaseFilterText ||
        obj[key] === lowerCaseFilterText ||
        obj[key]?.toString().toLowerCase() === lowerCaseFilterText
      ) {
        return true;
      }
    }
  }
  return false;
}
