import { Standard } from '@getvim/vim-connect';

const demographics: Standard.Entities.PatientDemographics = {
  firstName: 'Catherine',
  lastName: 'Johnson',
  dateOfBirth: '1990-12-29',
  gender: Standard.Entities.Gender.female,
  address: {
    address1: '1937 82nd St',
    city: 'New York',
    fullAddress: '1937 82nd St, New York, NY, 77666',
    state: 'NY',
    zipCode: '77666',
  },
};

const insurance: Standard.Entities.PatientInsurance = {
  memberId: 'EHA5382',
  ehrInsurance: 'Elderplan',
};

const contact_info: Standard.Entities.PatientContactInfo = {
  email: 'sapir@abc.com',
  homePhoneNumber: '888-888-8888',
};

const pcp: Standard.Entities.Provider = {
  providerEhrId: '66417',
  npi: '1881677680',
  demographics: {
    firstName: 'Mick',
    lastName: 'Abae',
  },
  specialty: [
    {
      description: 'Reproductive Endocrinology/Infertility',
    },
  ],
};

const problemList: Standard.Entities.Diagnosis[] = [
  {
    description: 'Type 2 diabetes mellitus with other specified complication',
    code: 'E11.69',
  },
  {
    description: 'Pure hyperglyceridemia',
    code: 'E78.1',
  },
];

const patientId: string = '150';

export const catherineJohnson: Standard.Events.PatientInContextPayload = {
  patientId,
  demographics,
  insurance,
  contact_info,
  problemList,
};
