import { Standard } from '@getvim/vim-connect';
import { gender } from '../common/gender';
import { address } from '../common/address';

export const patientDemographics: Standard.Entities.PatientDemographics = {
  firstName: '',
  lastName: '',
  middleName: '',
  dateOfBirth: '', // yyyy-MM-dd
  gender,
  address,
};
