import { isEmpty } from 'lodash-es';
import { Response } from '@getvim/vim-connect-app';

export function getActionResult(actionResponse: Response): string {
  const result: string = actionResponse.data || actionResponse.error?.message || '';
  return Array.isArray(result) && result.length === 0
    ? '[]'
    : isEmpty(result)
    ? 'Unknown error'
    : result;
}
