import { Types } from '../../../components/login-accordion/utils';
import { v4 as uuid } from 'uuid';

export const predefinedPayloads: Record<string, Types.VimEhrLoginCredentialsPayload> = {
  'simulator@getvim.com': {
    linkedEhrUsername: 'simulator@getvim.com',
    password: uuid(),
    username: `simulator@getvim.com${uuid()}`,
  },
};
