import React, { useMemo } from 'react';

export const SimulatorBridge = () => {
  const simulatorBridgeHost = useMemo(() => {
    const host = window.location.hostname;
    if (host.includes('localhost')) {
      return 'http://localhost:3019';
    }
    return `https://${host.replace('simulator', 'app-os-demo')}`;
  }, []);

  return (
    <iframe
      style={{ height: '500px', width: '100%' }}
      title="DemoAppSimulatorBridge"
      src={`${simulatorBridgeHost}/simulator-bridge`}
    ></iframe>
  );
};
