/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, createContext, useContext } from 'react';
import useLocalStorage from 'react-localstorage-hook';
import { xor } from 'lodash-es';

interface SettingsContextData {
  allSections: string[];
  selectedSections: string[];
  toggleSelectedSections: (section: string) => void;
}

export enum Sections {
  STANDART_EVENTS = 'Standart Events',
  VIM_CONNECT_UI_EVENTS = 'Vim Connect UI Events',
  LOGIN_EVENTS = 'Login Events',
  DEMO_APP_SIMULATOR_BRIDGE = 'Demo app simulator bridge',
}

export const SettingsContext = createContext<SettingsContextData>({} as SettingsContextData);

export const SettingsProvider = ({ children }) => {
  const context = useSettingsContextProvider();

  const { Provider } = SettingsContext;
  return <Provider value={context}>{children}</Provider>;
};

export const useSettingsContext = (): SettingsContextData => useContext(SettingsContext);

export const useSettingsContextProvider = (): SettingsContextData => {
  const allSections: Sections[] = [
    Sections.STANDART_EVENTS,
    Sections.VIM_CONNECT_UI_EVENTS,
    Sections.LOGIN_EVENTS,
    Sections.DEMO_APP_SIMULATOR_BRIDGE,
  ];

  const [initialSelectedSections, setInitialSelectedSections] = useLocalStorage(
    'selectedSections',
    allSections,
  );

  const [selectedSections, setSelectedSections] = useState<string[]>(
    initialSelectedSections || allSections,
  );

  const toggleSelectedSections = useCallback(
    (section: string) => {
      const newArray = xor(selectedSections, [section]);

      setSelectedSections(newArray);
      setInitialSelectedSections(newArray);
    },
    [selectedSections],
  );

  return {
    allSections,
    selectedSections,
    toggleSelectedSections,
  };
};
