export enum TabType {
  EVENTS = 'events',
  STATE = 'state',
  ACTIONS = 'actions',
  CONNECTORS = 'connectors',
  LOGS = 'logs',
  REPORTS = 'reports',
  AUTOMATIONS = 'automations',
  SETTINGS = 'settings',
}
