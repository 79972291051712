import { Standard } from '@getvim/vim-connect';

export const address: Standard.Entities.PatientAddress = {
  fullAddress: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipCode: '',
};

export const addressWithFull: Standard.Entities.AddressWithFull = {
  ...address,
  fullAddress: '',
};
