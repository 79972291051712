import { Insurer } from '@getvim-os/standard';
import { Standard } from '@getvim/vim-connect';

const demographics: Standard.Entities.PatientDemographics = {
  firstName: 'Judith',
  lastName: 'Fleming',
  dateOfBirth: '1940-08-05',
  gender: Standard.Entities.Gender.male,
  address: {
    fullAddress: '312 Walker Ave, Brooksville, FL, 34601',
    address1: '312 Walker Ave',
    city: 'Brooksville',
    state: 'FL',
    zipCode: '34601',
  },
};

const insurance: Standard.Entities.PatientInsurance = {
  memberId: 'H20558224',
  ehrInsurance: 'Florida Blue Cross Blue Shield',
  insurer: Insurer.FLORIDA_BLUE,
};

const contact_info: Standard.Entities.PatientContactInfo = {
  homePhoneNumber: '(999) 999-999999',
};

const patientId: string = '134781';

export const judithFleming: Standard.Events.PatientInContextPayload = {
  patientId,
  demographics,
  insurance,
  contact_info,
};
