const EXTENSION_HANDSHAKE_MESSAGE_TYPE = 'transparent-frame-message-channel-handshake';
const EXTENSION_HANDSHAKE_MESSAGE_REPLY_TYPE = 'transparent-frame-message-channel-handshake-reply';

export function transparencyService(): void {
  const isDesktop: boolean = window.navigator?.userAgent?.includes('Electron') ?? false;
  if (!isDesktop) {
    window.addEventListener('message', (message: MessageEvent<any>) => {
      if (message?.isTrusted === true && message.data?.type === EXTENSION_HANDSHAKE_MESSAGE_TYPE) {
        const port: MessagePort = message.ports[0];
        (window as any).$extensionTransparentPort = port;
        port.postMessage({
          type: EXTENSION_HANDSHAKE_MESSAGE_REPLY_TYPE,
        });
      }
    });
  }
}
