import React, { FC, useState, useEffect, MouseEvent, ChangeEvent } from 'react';
import {
  Button,
  MenuItem,
  FormControl,
  Switch,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { omit } from 'lodash-es';
import {
  AdapterKPI,
  DisplayMode,
  OptumProvidersType,
  OptumReportProviderType,
} from '@getvim/vim-connect-app';
import {
  getDisplayTime,
  sendDisplayModeData,
  sendStartupAuthMonitorsData,
  sendOrganizationConfigData,
  sendRunAuthenticatedMonitorsData,
  sendClearOSSimulatorData,
  sendCapabilitiesConfigData,
  sendAdapterKPIData,
  sendExtractEcwDataForOptum,
} from '../../../services';
import { ICONS_MAP, SettingsProps, HiddenJsonType } from '../../../types';
import { ButtonIcon, JsonEditor, JsonViewer, Selector } from '../common';

export const Settings: FC<SettingsProps> = ({
  settings,
  adapterInfo,
  displayMode,
  capabilitiesLastUpdate,
  adapterKPILastUpdate,
  startupAuthMonitors = false,
  onRunMonitorsClick,
  onDisplayModeChange,
  onOrganizationConfigChange,
}) => {
  const [successMessage, setSuccessMessage] = useState<any>({});
  const [organizationConfigPayload, setOrganizationConfigPayload] = useState<string>('');
  const [adapterInformation, setAdapterInformation] = useState<string>('');
  const [adapterConfig, setAdapterConfig] = useState<string>('');
  const [adapterKPI, setAdapterKPI] = useState<AdapterKPI | undefined>();
  const [startupAuthMonitorsLocal, setStartupAuthMonitorsLocal] = useState<boolean>(false);
  const [optumReportProviderType, setOptumReportProviderType] = useState<OptumProvidersType>({
    [OptumReportProviderType.CLINIC_PROVIDERS]: true,
    [OptumReportProviderType.REFERRED_PROVIDERS]: false,
  });

  useEffect((): void => {
    if (settings && adapterInfo) {
      const organizationConfig: any = omit(settings.sqlSecrets, 'to-string');
      if (adapterInfo.adapterConfig) {
        setAdapterConfig(adapterInfo.adapterConfig!);
      }
      if (adapterInfo.adapterKPI) {
        setAdapterKPI(adapterInfo.adapterKPI!);
      }
      organizationConfig.sqlSecrets = omit(organizationConfig.sqlSecrets, 'to-string');
      setOrganizationConfigPayload(organizationConfig);
      setAdapterInformation(omit(adapterInfo, ['adapterConfig', 'adapterKPI']));
    }
  }, [settings, adapterInfo]);

  useEffect((): void => {
    setStartupAuthMonitorsLocal(startupAuthMonitors);
  }, [startupAuthMonitors]);

  const handleSetBothClick = (e: MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    sendOrganizationConfigData(true, organizationConfigPayload);
    onRunMonitorsClick({ value: true, setIsAuth: true, sendData: true, setBoth: false });
    setMessage('organizationConfig', 'Organization config & monitors saved successfully');
  };

  const handleSetOrganizationConfigClick = (e: MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    sendOrganizationConfigData(false, organizationConfigPayload);
    setMessage('organizationConfig', 'Organization config saved successfully');
  };

  const handleRunMonitorsClick = (e: MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    sendRunAuthenticatedMonitorsData();
    onRunMonitorsClick({ value: true, setIsAuth: true, sendData: false, setBoth: false });
    setMessage('organizationConfig', 'Monitors run successfully');
  };

  const handleClearOSSimulatorDataClick = (): void => {
    sendClearOSSimulatorData();
    setMessage('storage', 'OS Simulator storage data cleared successfully');
  };

  const handleExtractEcwDataForOptum = (): void => {
    sendExtractEcwDataForOptum(optumReportProviderType);
    setMessage('', '');
  };

  const handleDisplayModeChange = (e: SelectChangeEvent): void => {
    const displayMode: DisplayMode = e.target.value as DisplayMode;
    onDisplayModeChange(displayMode);
    sendDisplayModeData(displayMode);
    setMessage('display', 'Display mode changed successfully');
  };

  const handleChangeOrganizationConfig = (newValue: any): void => {
    setOrganizationConfigPayload(newValue);
    onOrganizationConfigChange({
      ...settings,
      sqlSecrets: newValue,
    });
  };

  const handleCapabilitiesRefresh = (e: MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    sendCapabilitiesConfigData();
    setMessage('', '');
  };

  const handleAdapterKPIRefresh = (e: MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    sendAdapterKPIData();
    setMessage('', '');
  };

  const setMessage = (property: string, message: string): void => {
    setSuccessMessage({ [property]: message });
    setTimeout(() => {
      setSuccessMessage({});
    }, 3000);
  };

  const handleAuthenticatedMonitorsSwitch = (e: ChangeEvent<HTMLInputElement>) => {
    onRunMonitorsClick({
      value: e.target.checked,
      setIsAuth: true,
      sendData: true,
      setBoth: false,
      startupAuthMonitors: { value: e.target.checked },
    });
    setStartupAuthMonitorsLocal(e.target.checked);
    sendStartupAuthMonitorsData(e.target.checked);
    setMessage(
      'onStartup',
      `Automatically initiate authenticated monitors switched to ${
        e.target.checked ? 'on' : 'off'
      } successfully`,
    );
  };

  const handleOptumReportCheckboxChecked = (e: ChangeEvent<HTMLInputElement>) => {
    setOptumReportProviderType({
      ...optumReportProviderType,
      [e?.target?.value]: e?.target?.checked,
    });
  };

  return (
    <>
      <div className="settings">
        <div className="organization-config">
          <div className="text-divider">Set Organization Config to Adapter</div>
          <div className="organization-config-container">
            <div className="note">Note: If one of the values contains '\' add another '\'</div>
            <div className="settings-payload">
              <JsonEditor
                value={organizationConfigPayload}
                height={10}
                ignoreInvalidJsonOnChange
                onChange={handleChangeOrganizationConfig}
              />
            </div>
            <div className="settings-buttons">
              <Button
                className="button settings-button all"
                onClick={handleSetBothClick}
                variant="outlined"
              >
                Set both
              </Button>
              <Button
                className="button settings-button secrets"
                onClick={handleSetOrganizationConfigClick}
                variant="outlined"
              >
                Set config
              </Button>
              <Button
                className="button settings-button monitors"
                onClick={handleRunMonitorsClick}
                variant="outlined"
              >
                Run monitors
              </Button>
            </div>
            <div>
              {successMessage.organizationConfig && (
                <div className="success-message">{successMessage.organizationConfig}</div>
              )}
            </div>
          </div>
        </div>
        <div className="capabilities-config">
          <div className="text-divider">Get Adapter Capabilities</div>
          <div className="capabilities-container">
            Last update: {getDisplayTime(capabilitiesLastUpdate).displayTime}
            <div className="capabilities-payload">
              <JsonViewer
                id="capabilities"
                type={HiddenJsonType.CAPABILITIES}
                value={adapterConfig}
                collapsed={1}
              />
            </div>
            <div className="capabilities-button">
              <ButtonIcon
                id="capabilities-reshuffle"
                className="button mini capabilities"
                icon={ICONS_MAP['sync']}
                onClick={handleCapabilitiesRefresh}
              />
            </div>
          </div>
        </div>
        <div className="adapter-kpi">
          <div className="text-divider">Get Adapter KPI</div>
          <div className="adapter-kpi-container">
            Last update: {getDisplayTime(adapterKPILastUpdate).displayTime}
            <div className="adapter-kpi-payload">
              <textarea
                id="adapter-kpi-payload"
                autoComplete="off"
                readOnly
                value={adapterKPI ? JSON.stringify(adapterKPI) : ''}
              />
            </div>
            <div className="adapter-kpi-button">
              <ButtonIcon
                id="adapter-kpi-reshuffle"
                className="button mini adapter-kpi"
                icon={ICONS_MAP['sync']}
                onClick={handleAdapterKPIRefresh}
              />
            </div>
          </div>
        </div>
        <div className="info">
          <div className="text-divider">Information</div>
          <div className="info-container">
            {Object.entries(adapterInformation || {}).map(([key, value]) => (
              <div key={key} className="info-item">
                <div id={`${key}-info-label`} className="label">
                  {key}
                </div>
                <div id={`${key}-info-value`} className="value">
                  {value?.toString()}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="on-startup">
          <div className="text-divider">On Startup</div>
          <div className="on-startup-container">
            <div className="authenticated-monitors">
              <div className="label">Automatically initiate authenticated monitors</div>
              <div className="switcher">
                <Switch
                  checked={startupAuthMonitorsLocal}
                  onChange={handleAuthenticatedMonitorsSwitch}
                  color="warning"
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </div>
            </div>
            <div>
              {successMessage.onStartup && (
                <div className="success-message">{successMessage.onStartup}</div>
              )}
            </div>
          </div>
        </div>
        <div className="storage">
          <div className="text-divider">Data</div>
          <div className="storage-container">
            <div className="settings-buttons">
              <Button
                className="button settings-button"
                onClick={handleClearOSSimulatorDataClick}
                variant="outlined"
              >
                Clear Storage Data
              </Button>
            </div>
            <div className="settings-buttons">
              <Button
                className="button settings-button"
                onClick={handleExtractEcwDataForOptum}
                variant="outlined"
              >
                Optum Data Report
              </Button>
              <FormGroup row className="settings-form-group">
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleOptumReportCheckboxChecked}
                      color="warning"
                      defaultChecked
                      value={OptumReportProviderType.CLINIC_PROVIDERS}
                    />
                  }
                  label="Clinic Providers"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleOptumReportCheckboxChecked}
                      color="warning"
                      value={OptumReportProviderType.REFERRED_PROVIDERS}
                    />
                  }
                  label="Referred To Providers"
                />
              </FormGroup>
            </div>

            <div className="note">Note: The Optum report available only for ecw-web</div>
            <div className="note">Note: Press on ESC in order restore the window's position</div>
            <div>
              {successMessage.storage && (
                <div className="success-message">{successMessage.storage}</div>
              )}
            </div>
          </div>
        </div>
        <div className="display">
          <div className="text-divider">Display</div>
          <div className="display-container">
            <FormControl size="small">
              <Selector
                id="display-mode-selector"
                labelId="display-mode-selector-label"
                value={displayMode}
                onChange={handleDisplayModeChange}
              >
                <MenuItem value={DisplayMode.DARK}>{DisplayMode.DARK.toUpperCase()}</MenuItem>
                <MenuItem value={DisplayMode.LIGHT}>{DisplayMode.LIGHT.toUpperCase()}</MenuItem>
              </Selector>
            </FormControl>
            <div>
              {successMessage.display && (
                <div className="success-message">{successMessage.display}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Settings.displayName = 'Settings';
