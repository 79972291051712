import {
  OSLog,
  OSActionLog,
  OSWritebackLog,
  AutomationOperationType,
  OperationResult,
} from '@getvim/vim-connect-app';
import { SHORT_TO_OPERATION_TYPE } from '../../types';

export function updateActionLog(
  logs: OSLog[],
  id: string,
  endTime: number,
  runtime: number,
  status: string,
  result: string,
): OSLog {
  const relevantLogs: OSLog[] = logs?.filter((log: OSLog) => log.parentId === id) ?? [];
  let log: OSLog | undefined = undefined;
  if (relevantLogs?.length > 0) {
    log = relevantLogs.find(
      (log: OSLog) =>
        SHORT_TO_OPERATION_TYPE[log.operationType] === AutomationOperationType.ACTION &&
        !(log as OSActionLog).endTime,
    );
    if (log) {
      const actionLog: OSActionLog = log as OSActionLog;
      actionLog.endTime = endTime;
      actionLog.duration = runtime;
      actionLog.status = status === OperationResult.SUCCESS;
      actionLog.result = result;
      log = { ...actionLog } as OSLog;
    }
  }
  return log!;
}

export function updateWritebackLog(
  logs: OSLog[],
  id: string,
  endTime: number,
  status: string,
): OSLog {
  const relevantLogs: OSLog[] = logs?.filter((log: OSLog) => log.parentId === id) ?? [];
  let log: OSLog | undefined = undefined;
  if (relevantLogs?.length > 0) {
    log = relevantLogs.find(
      (log: OSLog) =>
        SHORT_TO_OPERATION_TYPE[log.operationType] === AutomationOperationType.WRITEBACK &&
        !(log as OSWritebackLog).endTime,
    );
    if (log) {
      const writebackLog: OSWritebackLog = log as OSWritebackLog;
      writebackLog.endTime = endTime;
      writebackLog.duration = (writebackLog.endTime! - writebackLog.startTime!) / 1000;
      writebackLog.status = status === OperationResult.SUCCESS;
      log = { ...writebackLog } as OSLog;
    }
  }
  return log!;
}
