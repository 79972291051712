import React, { ChangeEvent } from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { styled } from '@mui/system';
import { useSettingsContext } from '../../context';

export const CheckboxGroup = () => {
  const { allSections, toggleSelectedSections, selectedSections } = useSettingsContext();

  const handleChange = ({ target: { name } }: ChangeEvent<HTMLInputElement>) =>
    toggleSelectedSections(name);

  return (
    <StyledFormGroup>
      {allSections.map((section) => (
        <FormControlLabel
          key={`section-${section}-checkbox`}
          control={
            <Checkbox
              id={`section-${section}-checkbox`}
              onChange={handleChange}
              name={section}
              checked={selectedSections.includes(section)}
            />
          }
          label={section}
        />
      ))}
    </StyledFormGroup>
  );
};

export const StyledFormGroup = styled(FormGroup)`
  flex-direction: row;
  padding-bottom: 0.5rem;
`;
