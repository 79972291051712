import { Standard } from '@getvim/vim-connect';

type PrintEncounterAction =
  Standard.Actions.ActionsStandard[Standard.Actions.ActionNames.PRINT_ENCOUNTER];

export const printEncounter: {
  input: PrintEncounterAction['input'];
} = {
  input: {
    patientId: '',
    encounterId: '',
    pdfUploadUrl: '',
  },
};
