import React, { FC, useState, useEffect, useCallback } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import { MenuItem, FormControl } from '@mui/material';
import { OSReport } from '@getvim/vim-connect-app';
import { Report } from '../report';
import { addLeadingZero, sendReportNamesData, sendReportData } from '../../../services';
import { Selector, ButtonIcon, ButtonLoader } from '../common';
import { ReportsProps, ICONS_MAP } from '../../../types';

const SELECT_REPORT: string = 'Select Report';
const ALL_REPORTS: string = 'All Reports';

export const Reports: FC<ReportsProps> = ({ reportNames, reports }) => {
  const [reportNamesList, setReportNamesList] = useState<string[]>([SELECT_REPORT]);
  const [reportName, setReportName] = useState<string>(SELECT_REPORT);
  const [generatedReports, setGeneratedReports] = useState<OSReport[]>([]);

  useEffect((): void => {
    sendReportNamesData();
    setGeneratedReports(reports?.reports ?? []);
  }, [reports]);

  useEffect((): void => {
    if (reportNames && reportNames.length > 0) {
      let sortedReports: string[] = reportNames.sort((a: string, b: string) =>
        a.toLowerCase() < b.toLowerCase() ? -1 : 1,
      );
      sortedReports = [SELECT_REPORT, ALL_REPORTS, ...sortedReports];
      setReportNamesList(sortedReports);
    }
  }, [reportNames]);

  const handleRefreshReportsList = useCallback((): void => {
    sendReportNamesData();
  }, []);

  const handleReportTypeChange = useCallback(({ target: { value } }: SelectChangeEvent): void => {
    setReportName(value);
  }, []);

  const handleReport = useCallback(
    (download): void => {
      if (!download) {
        setGeneratedReports([]);
      }
      if (reportName === SELECT_REPORT) {
        return;
      }
      sendReportData(reportName, download);
    },
    [reportName],
  );

  const handleRunReport = useCallback((): void => {
    handleReport(false);
  }, [handleReport]);

  const handleDownloadReport = useCallback((): void => {
    handleReport(true);
  }, [handleReport]);

  const buttonsDisabled: boolean = reportName === SELECT_REPORT;

  return (
    <>
      <div className="reports">
        <ButtonIcon
          id="download-reports-list"
          className="button mini"
          onClick={handleDownloadReport}
          icon={ICONS_MAP['download']}
          disabled={buttonsDisabled}
        />
        <ButtonIcon
          id="refresh-reports-list"
          className="button mini"
          onClick={handleRefreshReportsList}
          icon={ICONS_MAP['sync']}
        />
        <FormControl size="small">
          <Selector
            id="report-name"
            className="report-name"
            labelId="report-name-label"
            value={reportName}
            onChange={handleReportTypeChange}
          >
            {(reportNamesList ?? []).map((name: string) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Selector>
        </FormControl>
        <ButtonLoader
          id="run-report"
          className="report-button"
          onClick={handleRunReport}
          disabled={buttonsDisabled}
          disableLoader
        />
      </div>
      <div className="text-divider">
        Reports ({addLeadingZero((generatedReports ?? []).length)})
      </div>
      {(generatedReports ?? []).map((report: OSReport) => (
        <Report key={report.id} report={report} />
      ))}
    </>
  );
};

Reports.displayName = 'Reports';
