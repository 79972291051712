import React, { FC, MouseEvent, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { JsonViewer } from '../common';
import { getMonitorDisplayTime, getIcon } from '../../../services';
import { ReportProps, ICONS_MAP, HiddenJsonType } from '../../../types';

export const Report: FC<ReportProps> = ({ report }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const { id, name, payload } = report;

  const handleExpandEvent = (e: MouseEvent<HTMLDivElement>): void => {
    const targetElement: HTMLElement = e.currentTarget as HTMLElement;
    if (!targetElement.classList.contains('adapter-report-title')) {
      return;
    }
    const classList: DOMTokenList | undefined = (e.target as any)?.parentNode?.classList;
    if (classList && (classList.contains('report-filter') || classList.contains('filter-report'))) {
      return;
    }
    setExpanded(!expanded);
  };

  const monitorDisplayTime: string | null = getMonitorDisplayTime(
    payload?.cyclesWithElement?.monitorRunTime || payload?.cyclesWithoutElement?.monitorRunTime,
  );

  return (
    <Accordion data-id={id} className="report-wrap" data-name={name} expanded={expanded}>
      <AccordionSummary
        onClick={handleExpandEvent}
        className="adapter-report-title"
        expandIcon={ICONS_MAP['expand-more']}
      >
        <div className="report">
          <div className="report-box report-icon">{getIcon(name)}</div>
          <div className="report-box report-title">{name}</div>
          <div className="report-box report-display-time">{`${monitorDisplayTime} sec`}</div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <JsonViewer id={name} type={HiddenJsonType.REPORT} value={payload} collapsed={1} />
      </AccordionDetails>
    </Accordion>
  );
};

Report.displayName = 'Report';
