import { Standard } from '@getvim/vim-connect';
import { EXPECTED_WRITEBACK_RESULTS_OBJECT } from '@getvim/vim-connect-app';
import { icdsList, getRowByKey } from '../../icds';

const invalidDescription: string = 'pass pass only win';
const letters: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const invalidTypes: string[] = ['code', 'description', 'both'];

function modifyICDValue(type: string): string {
  if (type === 'code') {
    const randomLetter: string = letters[Math.floor(Math.random() * letters.length)];
    return randomLetter + Math.floor(Math.random() * 100);
  } else if (type === 'description') {
    return invalidDescription;
  }
  return '';
}

function getRandomIndexes(icdsCount: number): number[] {
  const indexes: number[] = Array.from({ length: icdsList.size }, (_, i) => i);
  const randomIndexes: number[] = [];
  for (let i = 0; i < icdsCount; i++) {
    const randomIndex: number = Math.floor(Math.random() * indexes.length);
    randomIndexes.push(indexes.splice(randomIndex, 1)[0]);
  }
  return randomIndexes;
}

function getRandomInvalidType(): string {
  const index: number = Math.floor(Math.random() * invalidTypes.length);
  return invalidTypes[index];
}

function getICD(
  diagnosisArray: [string, string][],
  index: number,
  key: string,
): { code: string; description: string } {
  let code: string = '';
  let description: string = '';
  const [dDescription, dCode]: [string, string] = diagnosisArray[index];
  if (key === 'v') {
    code = dCode;
    description = dDescription;
  } else if (key === 'x') {
    const invalidType: string = getRandomInvalidType();
    switch (invalidType) {
      case 'both':
        code = modifyICDValue('code');
        description = modifyICDValue('description');
        break;
      case 'code':
        code = modifyICDValue('code');
        description = dDescription;
        break;
      case 'description':
        code = dCode;
        description = modifyICDValue('description');
        break;
    }
  }
  return {
    code,
    description,
  };
}

function getICDs(
  diagnosisArray: [string, string][],
  indexesList: number[],
  keysArray: string[],
): { code: string; description: string }[] {
  const randomDiagnoses: { code: string; description: string }[] = [];
  let i: number = 0;
  for (const index of indexesList) {
    const key: string = keysArray[i];
    randomDiagnoses.push(getICD(diagnosisArray, index, key));
    i += 1;
  }
  return randomDiagnoses;
}

export function isICDCode(key: string): boolean {
  return key[0] === 'R';
}

export function getWritebackAssessmentsPayload(key: string): Standard.WriteBacks.UpdateEncounter {
  if (!key) {
    throw new Error('Missing a key');
  }
  if (isICDCode(key)) {
    const matchICD: [string, string] | undefined = getRowByKey(key);
    return {
      addedDiagnoses: [
        {
          code: matchICD ? matchICD[1] : '',
          description: matchICD ? matchICD[0] : '',
        },
      ],
      addedProcedures: [],
    };
  }
  const keysArray: string[] = key.split('');
  const diagnosisArray: [string, string][] = Array.from(icdsList.entries());
  const indexesList: number[] = getRandomIndexes(keysArray.length);
  const randomDiagnoses: { code: string; description: string }[] = getICDs(
    diagnosisArray,
    indexesList,
    keysArray,
  );
  return {
    addedDiagnoses: randomDiagnoses,
    addedProcedures: [],
  };
}

export const EXPECTED_WRITEBACK_RESULTS_EXTENDED_OBJECT: Record<string, string> = {
  ...Object.fromEntries(icdsList.entries()),
  ...EXPECTED_WRITEBACK_RESULTS_OBJECT,
};
