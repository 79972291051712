import React, { FC, useEffect, useState, MouseEvent, useCallback } from 'react';
import { MenuItem, FormControl } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { v4 as uuid } from 'uuid';
import { Standard } from '@getvim/vim-connect';
import { OperationResult, Response, OSActionLog, OSLog } from '@getvim/vim-connect-app';
import {
  sendActionData,
  createLog,
  updateActionLog,
  SELECT_ACTION,
  sortedActions,
  getActionResult,
  removeToString,
} from '../../../services';
import { OPERATION_TYPE_TO_SHORT, ActionsProps, LoaderSide } from '../../../types';
import { getActionPredefinedPayload } from '../../mocks/actions';
import { ButtonLoader, JsonEditor, Selector } from '../common';

export const Actions: FC<ActionsProps> = ({
  actionData,
  actionResponse,
  localLogs,
  onLogsUpdate,
  onActionDataChange,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [actionType, setActionType] = useState<string>(sortedActions[0]);
  const [payload, setPayload] = useState<any>('');
  const [actionLocalResponse, setActionLocalResponse] = useState<Response | undefined>(undefined);
  const [isActionDataUpdated, setIsActionDataUpdated] = useState<boolean>(false);

  const setActionDataFromStorage = useCallback((actionData: any): void => {
    try {
      if (actionData.actionName) {
        setActionType(actionData.actionName.toString());
      }
      if (actionData.payload) {
        const payloadToInject = removeToString(actionData.payload);
        setPayload(payloadToInject);
      }
    } catch (error) {
      console.error('Error setting action data from storage', error);
    }
  }, []);

  useEffect(() => {
    if (actionData && !isActionDataUpdated) {
      setActionDataFromStorage(actionData);
      setIsActionDataUpdated(true);
    }
  }, [actionData, isActionDataUpdated, setActionDataFromStorage]);

  useEffect((): void => {
    setActionLocalResponse(actionResponse);
    if (actionResponse) {
      setIsLoading(false);
      const log: OSLog | undefined = localLogs.find(
        (log: OSLog) => log.parentId === actionResponse.id,
      );
      if (log && !(log as OSActionLog).result) {
        onLogsUpdate([
          updateActionLog(
            localLogs,
            actionResponse.id,
            actionResponse.runtime,
            Date.now(),
            OperationResult.SUCCESS,
            getActionResult(actionResponse),
          ),
        ]);
      }
    }
  }, [actionResponse, localLogs, onLogsUpdate]);

  const handleActionSelectChange = (e: SelectChangeEvent): void => {
    if (e.target.value === SELECT_ACTION) {
      setActionType(SELECT_ACTION);
      setPayload('');
      return;
    }
    const actionName: Standard.Actions.ActionNames = Standard.Actions.ActionNames[e.target.value];
    setActionType(actionName);
    setPayload(getActionPredefinedPayload(actionName)?.input);
  };

  const handleJsonEditorChange = (newValue: any): void => {
    setPayload(newValue);
  };

  const handleRunClick = (e: MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    setIsLoading(true);
    setActionLocalResponse(undefined);
    const requestId: string = uuid();
    sendActionData({
      payload: {
        id: requestId,
        actionName: actionType,
        input: payload,
      },
    });
    onLogsUpdate([
      ...localLogs,
      createLog(requestId, OPERATION_TYPE_TO_SHORT['action'], actionType, Date.now()),
    ]);
    onActionDataChange({
      actionName: actionType as Standard.Actions.ActionNames,
      payload,
    });
  };

  return (
    <div className="actions">
      <FormControl size="small">
        <div className="actions-select">
          <Selector
            id="action-selector"
            labelId="action-selector-label"
            value={actionType}
            onChange={handleActionSelectChange}
          >
            {sortedActions.map((key: string) => (
              <MenuItem key={key} value={key}>
                {Standard.Actions.ActionNames[key] ?? key}
              </MenuItem>
            ))}
          </Selector>
        </div>
        <ButtonLoader
          id="action-run"
          className="button action-button"
          onClick={handleRunClick}
          isLoading={isLoading}
          disabled={actionType === SELECT_ACTION}
          loaderSide={LoaderSide.LEFT}
        />
      </FormControl>
      <div></div>
      <div className="action-payload">
        <JsonEditor
          value={payload}
          height={10}
          ignoreInvalidJsonOnChange
          onChange={handleJsonEditorChange}
        />
      </div>
      <div className="action-response">
        {actionLocalResponse && (
          <>
            <div>Response: (In {actionLocalResponse.runTime} seconds)</div>
            <JsonEditor value={getActionResult(actionResponse)} height={10} />
          </>
        )}
      </div>
    </div>
  );
};

Actions.displayName = 'Actions';
