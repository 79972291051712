import { Types } from '../../../components/login-accordion/utils';

export const predefinedPayloads: Record<string, Types.VimEhrDisplayReLoginScreenPayload> = {
  'Example Message': {
    message: 'Example Message',
  },
  'Oops!': {
    message: 'lorem ipsum',
  },
};
