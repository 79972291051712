import { Standard } from '@getvim/vim-connect';

type GetPatientEncountersAction =
  Standard.Actions.ActionsStandard[Standard.Actions.ActionNames.GET_PATIENT_ENCOUNTERS];

export const getPatientEncounters: {
  input: GetPatientEncountersAction['input'];
} = {
  input: {
    patientId: '',
    fromDate: '', // MM/DD/YYYY format
    untilDate: '', // MM/DD/YYYY format
  },
};
