import React, { FC } from 'react';
import { LoginAccordion } from '.';
import { Box } from '@mui/material';
import { Header } from '../layout/Header';

export const LoginMain: FC = () => (
  <Box className="accordion-wrapper">
    <Header title="Login Events" />
    <LoginAccordion />
  </Box>
);
