import { OSLog } from '@getvim/vim-connect-app';
import { v4 as uuid } from 'uuid';

export function createAutoTestLog(
  parentId: string,
  operationType: string,
  type: string,
  count: number,
  startTime: number,
  endTime: number,
  resultRun: string,
  resultSum: string,
): OSLog {
  return {
    id: uuid(),
    parentId,
    createTime: Date.now(),
    operationType,
    type,
    status: true,
    startTime,
    endTime,
    count,
    resultRun,
    resultSum,
    isAutoTest: true,
  };
}
