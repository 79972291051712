import React, { FC } from 'react';
import { isEmpty } from 'lodash-es';
import MonacoEditorReact from '@monaco-editor/react';
import { Standard } from '@getvim/vim-connect';

interface MonacoEditorProps {
  height: string;
  onChange: (newValue) => void;
  value: typeof Standard.Events | undefined;
  isDarkMode?: boolean;
}

export const MonacoEditor: FC<MonacoEditorProps> = ({
  height,
  onChange,
  value,
  isDarkMode = false,
}) => (
  <MonacoEditorReact
    onChange={(newValue) => onChange(newValue ? JSON.parse(newValue) : '')}
    height={height}
    value={isEmpty(value) ? undefined : JSON.stringify(value, undefined, 4)}
    defaultLanguage="json"
    theme={isDarkMode ? 'vs-dark' : 'light'}
    options={{
      minimap: { enabled: false },
      formatOnPaste: true,
      formatOnType: true,
      scrollBeyondLastLine: true,
      trimAutoWhitespace: true,
      fontSize: 16,
      autoIndent: 'full',
    }}
  />
);
