import { CommonConnector, DesktopConnector, WebConnector } from '@getvim/extension-connector';

export enum ConnectorsType {
  COMMON = 'common',
  DESKTOP = 'desktop',
  WEB = 'web',
}

export const connectors: Record<string, any> = {
  [ConnectorsType.COMMON]: CommonConnector,
  [ConnectorsType.DESKTOP]: DesktopConnector,
  [ConnectorsType.WEB]: WebConnector,
};

export type ExecuteConnectorFunction = {
  type: string;
  funcName: string;
  payload: any;
};

export const connectorsTypeList = [
  { key: 'select', value: 'Select Connectors Type' },
  { key: ConnectorsType.COMMON, value: 'CommonConnector' },
  { key: ConnectorsType.DESKTOP, value: 'DesktopConnector' },
  { key: ConnectorsType.WEB, value: 'WebConnector' },
];
