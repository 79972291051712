import { Standard } from '@getvim/vim-connect';

const demographics: Standard.Entities.PatientDemographics = {
  firstName: 'Ryan',
  lastName: 'Thompson',
  dateOfBirth: '1958-08-21',
  gender: Standard.Entities.Gender.female,
  address: {
    zipCode: '34601',
  },
};

const insurance: Standard.Entities.PatientInsurance = {
  memberId: '5D88XW7UF30',
};

const contact_info: Standard.Entities.PatientContactInfo = {
  homePhoneNumber: '123-123-1231',
};

const problemList: Standard.Entities.Diagnosis[] = [
  { description: 'Irritable bowel disease', code: 'K58.9' },
  { description: 'AA (alcohol abuse)', code: 'F10.10' },
  {
    description: 'Other infective spondylopathies, sacral and sacrococcygeal region',
    code: 'M46.58',
  },
];

const patientId: string = 'vim_test_fldemz_1';

export const ryanThompson: Standard.Events.PatientInContextPayload = {
  patientId,
  demographics,
  insurance,
  contact_info,
  problemList,
};
