export const icdsList: Map<string, string> = new Map([
  ['Abdominal distension (gaseous)', 'R14.0'],
  ['Incomplete defecation', 'R15.0'],
  ['Liver masses', 'R16.0'],
  ['Icterus gravis', 'R17'],
  ['Fluid in peritoneal cavity', 'R18.8'],
]);

export const getRowByKey = (key: string): [string, string] | undefined => {
  const keys: string[] = Array.from(icdsList.keys());
  for (const description of keys) {
    const code: string | undefined = icdsList.get(description);
    if (code === key) {
      return [description, code];
    }
  }
  return undefined;
};
