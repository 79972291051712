import { Standard } from '@getvim/vim-connect';
import { patientDemographics } from './patientDemographics';
import { patientContactInfo } from './patientContactInfo';
import { patientInsurance } from './patientInsurance';
import { provider } from '../provider';
import { diagnosis } from '../diagnosis';

export const patient: Standard.Entities.Patient = {
  patientId: '',
  mrn: '',
  demographics: patientDemographics,
  insurance: patientInsurance,
  contact_info: patientContactInfo,
  pcp: provider,
  problemList: [diagnosis],
  vimPatientId: '',
};
