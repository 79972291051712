import React from 'react';
import { Button } from '@mui/material';

export const InlineSimulatorWidget = () => {
  const buttonStyle: { backgroundColor: string; color: string } = {
    backgroundColor: '#b84c22',
    color: 'white',
  };

  return (
    <Button type="button" style={buttonStyle}>
      Inline Simulator Widget
    </Button>
  );
};

InlineSimulatorWidget.displayName = 'InlineSimulatorWidget';
