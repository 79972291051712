import { Standard } from '@getvim/vim-connect';

type PrintEncountersAction =
  Standard.Actions.ActionsStandard[Standard.Actions.ActionNames.PRINT_ENCOUNTERS];

export const printEncounters: {
  input: PrintEncountersAction['input'];
} = {
  input: {
    encounterIds: [''],
    patientId: '',
  },
};
