import { Insurer } from '@getvim-os/standard';
import { Standard } from '@getvim/vim-connect';

const demographics: Standard.Entities.PatientDemographics = {
  firstName: 'Patricia',
  lastName: 'Bell',
  dateOfBirth: '1950-09-06',
  gender: Standard.Entities.Gender.female,
  address: {
    address1: '312 Walker Ave',
    city: 'BROOKSVILLE',
    fullAddress: '312 Walker Ave, BROOKSVILLE, FL, 34601',
    state: 'FL',
    zipCode: '34601',
  },
};

const insurance: Standard.Entities.PatientInsurance = {
  memberId: 'H32880421',
  payerId: '00590',
  ehrInsurance: 'Florida Blue',
  insurer: Insurer.FLORIDA_BLUE,
};

const contact_info: Standard.Entities.PatientContactInfo = {
  email: '123123@lalala.com',
  homePhoneNumber: '123-123-1231',
};

const problemList: Standard.Entities.Diagnosis[] = [
  { description: 'Irritable bowel disease', code: 'K58.9' },
  { description: 'AA (alcohol abuse)', code: 'F10.10' },
  {
    description: 'Other infective spondylopathies, sacral and sacrococcygeal region',
    code: 'M46.58',
  },
];

const patientId: string = '137931';

export const patriciaBell: Standard.Events.PatientInContextPayload = {
  patientId,
  demographics,
  insurance,
  contact_info,
  problemList,
};
