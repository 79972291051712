import { Standard } from '@getvim/vim-connect';
import { orderA, orderB, orderC } from '../orders';
import { minnieMouse, natashaDavies, stevenVazquez } from '../patients';

export const predefinedPayloads: Record<string, Standard.Events.OrderCreatedPayload> = {
  'Minnie Mouse - Order A': {
    patient: minnieMouse as Standard.Events.TransformedPatientInContextPayload,
    order: orderA,
  },
  'Steven Vazquez - Order B': {
    patient: stevenVazquez as Standard.Events.TransformedPatientInContextPayload,
    order: orderB,
  },
  'Natasha Davies - Order C': {
    patient: natashaDavies as Standard.Events.TransformedPatientInContextPayload,
    order: orderC,
  },
};
