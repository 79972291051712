import { Standard } from '@getvim/vim-connect';

type GetEncounterVitalsAction =
  Standard.Actions.ActionsStandard[Standard.Actions.ActionNames.GET_ENCOUNTER_VITALS];

export const getEncounterVitals: {
  input: GetEncounterVitalsAction['input'];
} = {
  input: {
    encounterId: '',
    patientId: '',
  },
};
