import {
  ApolloClient,
  ApolloQueryResult,
  MutationOptions,
  OperationVariables,
  QueryOptions,
} from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { RetryLink } from 'apollo-link-retry';
import { StandardError } from '../../standard/errors';
import { ErrorCodes } from '../../standard';
import { OperationDefinitionNode, SelectionNode } from 'graphql';
import gqlTag from 'graphql-tag';

export const gql = gqlTag;

const RETRIES_ERRORS_WHITELIST = [
  'Failed to fetch',
  'ECONNRESET',
  'ENOTFOUND',
  'EHOSTUNREACH',
  'ECONNREFUSED',
  'socket hang up',
];

const RETRY_MUTATION_WHITELIST = new Set(['initialLogin']);

function isNetworkError(error) {
  return (
    error &&
    (RETRIES_ERRORS_WHITELIST.includes(error.message) ||
      RETRIES_ERRORS_WHITELIST.find(
        (errorCode) => error.code === errorCode || error.message.includes(errorCode),
      ))
  );
}

const MAX_RETRIES = 3;
const RETRY_DELAY = 250;

export class NoAccessTokenError extends StandardError {
  constructor(context) {
    super('No Access Token Received', ErrorCodes.UNAUTHORIZED, context);
  }
}

class VimConnectGraphQLClient extends ApolloClient<NormalizedCacheObject> {
  private accessToken?: string;
  private deviceId?: string;
  private isVimOsEnabled?: boolean;

  constructor() {
    const httpLink = createHttpLink({ uri: '/api/graphql' });

    const authLink = setContext((_, { headers }) => {
      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...(this.accessToken ? { authorization: this.accessToken } : undefined),
          ...(this.deviceId ? { ['x-vim-device-id']: this.deviceId } : undefined),
          ...(this.isVimOsEnabled !== undefined
            ? { ['x-vim-os-enabled']: this.isVimOsEnabled }
            : undefined),
          ...headers,
        },
      };
    });

    const errorLink = new ApolloLink((operation, forward) => {
      return forward(operation).map((data) => {
        if (data && data.errors) {
          const networkError = data.errors.find(isNetworkError);
          if (networkError) {
            throw new Error(networkError.message);
          }
        }
        return data;
      });
    });

    const retryLink = new RetryLink({
      delay: {
        initial: RETRY_DELAY,
        max: Infinity,
        jitter: false,
      },
      attempts: {
        max: MAX_RETRIES,
        retryIf: (error, operation) => {
          // on 504s cloudflare returns an html page including "vim is baking ..."
          const isBaking = error?.bodyText?.toLowerCase().includes('baking');

          const operationDefinition = operation.query.definitions[0] as OperationDefinitionNode;
          const operationType = operationDefinition?.operation;

          const operationName = this.getOperationName(operationDefinition);

          const shouldRetry =
            operationType === 'query' || RETRY_MUTATION_WHITELIST.has(operationName);

          return shouldRetry && (isBaking || isNetworkError(error));
        },
      },
    });

    super({
      link: ApolloLink.from([retryLink, authLink, errorLink, httpLink]),
      cache: new InMemoryCache({ resultCaching: true }),
    });
  }

  public setAccessToken(token: string) {
    this.accessToken = token;
  }

  public setIsVimOsEnabled(isVimOsEnabled: boolean) {
    this.isVimOsEnabled = isVimOsEnabled;
  }

  public setDeviceId(deviceId: string) {
    this.deviceId = deviceId;
  }

  public getDeviceId() {
    return this.deviceId;
  }

  private getOperationName(operationDefinition: OperationDefinitionNode): string {
    return (
      (
        operationDefinition.selectionSet?.selections?.[0] as SelectionNode & {
          name: { value: string };
        }
      )?.name?.value ||
      operationDefinition.name?.value ||
      ''
    );
  }

  mutateAuthenticated<T = any, TVariables = OperationVariables>(
    options: MutationOptions<T, TVariables>,
  ): ReturnType<ApolloClient<NormalizedCacheObject>['mutate']> {
    if (!this.accessToken) {
      throw new NoAccessTokenError({
        options,
        accessToken: this.accessToken,
        deviceId: this.deviceId,
      });
    }

    return this.mutate(options);
  }

  async mutate<T = any, TVariables = OperationVariables>(options: MutationOptions<T, TVariables>) {
    try {
      return await super.mutate(options);
    } catch (error) {
      if (error) {
        // eslint-disable-next-line
        // @ts-ignore
        error.options = options;
      }
      throw error;
    }
  }

  async query<T = any, TVariables = OperationVariables>(
    options: QueryOptions<TVariables>,
  ): Promise<ApolloQueryResult<T>> {
    try {
      return await super.query(options);
    } catch (error) {
      if (error) {
        // eslint-disable-next-line
        // @ts-ignore
        error.options = options;
      }
      throw error;
    }
  }

  public queryAuthenticated<
    T extends any = any,
    TVariables extends OperationVariables = OperationVariables,
  >(options: QueryOptions<TVariables>): Promise<ApolloQueryResult<T>> {
    if (!this.accessToken) {
      throw new NoAccessTokenError({
        options,
        accessToken: this.accessToken,
        deviceId: this.deviceId,
      });
    }

    return this.query<T, OperationVariables>(options);
  }
}

export const gqlClient = new VimConnectGraphQLClient();
