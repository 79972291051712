import { Standard } from '@getvim/vim-connect';

type GetEncounterDataAction =
  Standard.Actions.ActionsStandard[Standard.Actions.ActionNames.GET_ENCOUNTER_DATA];

export const getEncounterData: {
  input: GetEncounterDataAction['input'];
} = {
  input: {
    encounterId: '',
    patientId: '',
  },
};
