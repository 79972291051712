import { WidgetIncomingEvent } from '@getvim/vim-connect-app';
import { EventType } from '@getvim/utils-vim-connect-communication';

export const handlePublish = (data: any, event: WidgetIncomingEvent | EventType) => {
  const sendDataToVimConnectUI = 'vim-connect-send-data-to-vim-connect-ui';
  const hubSelector = 'div[vim-connect-widget-id="vim-connect-ui"] iframe';
  const osSelector = 'div[vim-connect-widget-id="os-iframe-wrapper"] iframe#vim-os-iframe';

  document
    .querySelector<HTMLIFrameElement>(hubSelector)
    ?.contentWindow?.postMessage({ payload: { event, data }, type: sendDataToVimConnectUI }, '*');

  document
    .querySelector<HTMLIFrameElement>(osSelector)
    ?.contentWindow?.postMessage({ payload: { event, data }, type: sendDataToVimConnectUI }, '*');
};
