import { Insurer } from '@getvim-os/standard';
import { Standard } from '@getvim/vim-connect';

export const dummyPatient: Standard.Events.PatientInContextPayload = {
  patientId: '140820',
  demographics: {
    firstName: 'Dummy',
    lastName: 'Patient',
    dateOfBirth: '2005-05-06',
    gender: Standard.Entities.Gender.male,
    address: {
      fullAddress: 'FL',
      address1: '',
      address2: '',
      city: 'North Carolina',
      state: 'FL',
    },
  },
  insurance: {
    memberId: '9115',
    groupId: '',
    payerId: '',
    ehrInsurance:
      'This patient has a very long text for his insurance which can be used to test the tooltip',
    insurer: Insurer.UHC,
  },
  contact_info: {
    homePhoneNumber: '800-352-2583',
    mobilePhoneNumber: '',
    email: 'jbjkb@gmail.com',
  },
};
