import { TabItem, TabType, ICONS_MAP } from '../../../types';

export function getTabs(): TabItem[] {
  return [
    { name: TabType.EVENTS, icon: ICONS_MAP[TabType.EVENTS] },
    { name: TabType.STATE, icon: ICONS_MAP[TabType.STATE] },
    { name: TabType.ACTIONS, icon: ICONS_MAP[TabType.ACTIONS] },
    { name: TabType.CONNECTORS, icon: ICONS_MAP[TabType.CONNECTORS] },
    { name: TabType.LOGS, icon: ICONS_MAP[TabType.LOGS] },
    { name: TabType.REPORTS, icon: ICONS_MAP[TabType.REPORTS] },
    { name: TabType.AUTOMATIONS, icon: ICONS_MAP[TabType.AUTOMATIONS] },
    { name: TabType.SETTINGS, icon: ICONS_MAP[TabType.SETTINGS] },
  ];
}
