import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useLocalStorage from 'react-localstorage-hook';
import { isEmpty } from 'lodash-es';
import { VimEhrLoginCredentials } from '../../mocks/login-events';
import { FlexBox } from '../common/FlexBox';
import { MonacoEditor } from '../monaco-editor';
import { Types, handlePublish } from './utils';

export const VimEhrLoginCredentialsTab = () => {
  const [payload, setPayload] = useState<Types.VimEhrLoginCredentialsPayload>();
  const [predefinedPayload, setPredefinedPayload] = useState<string>('');
  const [lastUsedPayload, setLastUsedPayload] = useLocalStorage(
    'lastVimEhrLoginCredentialsPayload',
    '',
  );

  useEffect(() => {
    handlePredefinedPayloadChange({ target: { value: lastUsedPayload } } as SelectChangeEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePredefinedPayloadChange = ({ target: { value } }: SelectChangeEvent) => {
    setPredefinedPayload(value);
    setLastUsedPayload(value);
    setPayload(VimEhrLoginCredentials.predefinedPayloads[value]);
  };

  return (
    <Accordion id="vimEhrLoginCredentialsTab">
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>VimEhrLoginCredentials</Typography>
        <Select
          id="select-vim-ehr-login-credentials"
          displayEmpty
          value={predefinedPayload}
          size="small"
          onChange={handlePredefinedPayloadChange}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <MenuItem value="">Choose</MenuItem>
          {Object.keys(VimEhrLoginCredentials.predefinedPayloads).map((value, index) => (
            <MenuItem id={`select-vim-ehr-login-credentials-${index}`} value={value} key={value}>
              {value}
            </MenuItem>
          ))}
        </Select>

        <Button
          id="publish-vim-ehr-login-credentials"
          disabled={isEmpty(predefinedPayload)}
          fullWidth
          onClick={(e) => {
            e.stopPropagation();
            handlePublish(payload, 'vim-ehr-login-credentials');
          }}
          variant="contained"
        >
          Publish
        </Button>
      </AccordionSummary>
      <AccordionDetails>
        <MonacoEditor onChange={setPayload} height="10rem" value={payload} />

        <FlexBox>
          <TextField
            id="vimEhrLoginCredentialsPayload"
            variant="outlined"
            size="small"
            value={JSON.stringify(payload)}
            sx={{ display: 'none' }}
          />
        </FlexBox>
      </AccordionDetails>
    </Accordion>
  );
};
