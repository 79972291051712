import React, { FC, RefObject, useRef } from 'react';
import { HiddenDataProps, TabType } from '../../../types';

export const HiddenData: FC<HiddenDataProps> = ({
  osSimulatorData,
  events,
  filteredEvents,
  filterText,
  onNavigateToTab,
}) => {
  const navigateToTabInputRef: RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);
  const navigateToTabButtonRef: RefObject<HTMLButtonElement> = useRef<HTMLButtonElement>(null);
  const displayEvents: any[] = filterText ? filteredEvents : events;

  const handleNavigateToTab = (): void => {
    const navigateToTabInputType: string | undefined = navigateToTabInputRef?.current?.value;
    if (navigateToTabInputType) {
      onNavigateToTab(navigateToTabInputType as TabType);
    }
  };

  return (
    <>
      <div id="os-simulator-hidden-data" className="hidden-element">
        {osSimulatorData ? JSON.stringify(osSimulatorData) : null}
      </div>
      <div id="events-list" className="hidden-element">
        [{displayEvents.map(({ eventName }) => eventName).join(',')}]
      </div>
      <input
        id="navigate-to-tab-input"
        className="hidden-element"
        type="text"
        ref={navigateToTabInputRef}
      />
      <button
        id="navigate-to-tab-button"
        className="hidden-element"
        defaultValue=""
        ref={navigateToTabButtonRef}
        onClick={handleNavigateToTab}
      />
    </>
  );
};

HiddenData.displayName = 'HiddenData';
