import { Standard } from '@getvim/vim-connect';
import { v4 as uuid } from 'uuid';
import { encounterIdA } from '../encounters';

export const orderE: Standard.Entities.Order = {
  id: uuid(),
  type: Standard.Entities.OrderType.LAB,
  icd: [],
  cpts: [
    {
      name: 'EXC COARCJ aorta w/wo PDA w/direct anastomosis',
      code: '33840',
    },
  ],
  encounterId: encounterIdA,
  createdDate: '2000-01-01',
};
