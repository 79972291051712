import { Standard } from '@getvim/vim-connect';

type GetPatientScheduledAppointmentsAction =
  Standard.Actions.ActionsStandard[Standard.Actions.ActionNames.GET_PATIENT_SCHEDULED_APPOINTMENTS];

export const getPatientScheduledAppointments: {
  input: GetPatientScheduledAppointmentsAction['input'];
} = {
  input: {
    patientId: '',
  },
};
