import React, { FC } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import { TabsMenuProps, TabItem } from '../../../types';
import { getTabs } from './getTabs';

export function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

export const TabsMenu: FC<TabsMenuProps> = ({
  tabId,
  widgetExpanded,
  isAuthMonitorRunning,
  onTabChange,
}) => {
  let tabIndex: number = -1;
  const tabs: TabItem[] = getTabs();
  return (
    <Box
      className={`tabs${isAuthMonitorRunning ? ' clear' : ''}`}
      sx={{ borderBottom: 1, borderColor: 'divider' }}
    >
      <Tabs value={widgetExpanded ? tabId : false} aria-label="os simulator" onChange={onTabChange}>
        {tabs.map(({ name, icon, disabled, iconOnly }) => (
          <Tab
            key={name}
            label={iconOnly ? null : name}
            aria-label={name}
            icon={icon}
            iconPosition="start"
            disabled={disabled}
            className={`${iconOnly ? 'icon-only' : ''}`}
            {...a11yProps(++tabIndex)}
          />
        ))}
      </Tabs>
    </Box>
  );
};

TabsMenu.displayName = 'TabsMenu';
