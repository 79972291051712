import { Standard } from '@getvim/vim-connect';
import { providerDemographics } from './providerDemographics';
import { organization } from '../organization';
import { specialty } from '../common';
import { visitType } from '../visit-type';

export const provider: Standard.Entities.Provider = {
  providerEhrId: '',
  npi: '',
  demographics: providerDemographics,
  organization: organization,
  organizations: [organization],
  specialty: [specialty],
  visitTypes: [visitType],
};
