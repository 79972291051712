export const getDisplayTime = (
  targetDate?: Date,
  fullDate: boolean = false,
): { displayTime: string; timestamp: number } => {
  if (!targetDate) {
    return { displayTime: 'N/A', timestamp: 0 };
  }
  const date: Date = targetDate;
  const timeFormat = `${(date.getHours() < 10 ? '0' : '') + date.getHours()}:${
    (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
  }:${(date.getSeconds() < 10 ? '0' : '') + date.getSeconds()}.${
    (date.getMilliseconds() < 100 ? '0' : '') +
    (date.getMilliseconds() < 10 ? '0' : '') +
    date.getMilliseconds()
  }`;
  const displayTimeFormat: string = fullDate
    ? `${(date.getDate() < 10 ? '0' : '') + date.getDate()}/${
        (date.getMonth() < 9 ? '0' : '') + (date.getMonth() + 1)
      }/${date.getFullYear()} ${timeFormat}`
    : timeFormat;
  return {
    displayTime: displayTimeFormat,
    timestamp: date.getTime(),
  };
};
