import { Standard } from '@getvim/vim-connect';
import { minnieMouse, natashaDavies, stevenVazquez, tonyStark } from '../patients';
import { referralA, referralB, referralC, referralD } from '../referrals';

export const predefinedPayloads: Record<string, Standard.Events.ReferralViewedPayload> = {
  'Minnie Mouse - Referral A': { patient: minnieMouse, ...referralA },
  'Steven Vazquez - Referral B': { patient: stevenVazquez, ...referralB },
  'Natasha Davies - Referral C': { patient: natashaDavies, ...referralC },
  'Tony Stark - Referral D': { patient: tonyStark, ...referralD },
};
