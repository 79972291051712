import React from 'react';
import { Select, SelectChangeEvent } from '@mui/material';
import { SelectorProps } from '../../../types';
import { isDarkMode } from '../../../services';

export const Selector = <T,>({ children, onChange, ...otherProps }: SelectorProps<T>) => {
  const isDarkModeValue: boolean = isDarkMode();
  const fontColor: string = isDarkModeValue ? '#ffffff' : '#000000';
  return (
    <Select
      inputProps={{
        MenuProps: {
          PaperProps: {
            sx: {
              backgroundColor: isDarkModeValue ? '#6c6c6c' : '#4b4cff',
              borderRadius: 0,
              color: fontColor,
            },
          },
          MenuListProps: {
            sx: {
              '&& .Mui-selected, && .Mui-selected:hover, && .Mui-selected:focus, && .Mui-selected:active':
                {
                  color: fontColor,
                  backgroundColor: isDarkModeValue ? '#b84c22' : '#4b4cff',
                },
            },
          },
        },
        ...(otherProps.inputProps || {}),
      }}
      IconComponent={(props) => <span {...props}>{'\u25BC'}</span>}
      onChange={(event, child) => onChange(event as SelectChangeEvent<T>, child)}
      {...otherProps}
    >
      {children}
    </Select>
  );
};

Selector.displayName = 'Selector';
