import { Standard } from '@getvim/vim-connect';

type GetPatientAction = Standard.Actions.ActionsStandard[Standard.Actions.ActionNames.GET_PATIENT];

export const getPatient: {
  input: GetPatientAction['input'];
} = {
  input: {
    patientId: '',
    disableCache: true,
  },
};
