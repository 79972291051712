import { EventType } from '../../types';

export function getEventType(payload: any): EventType {
  if (!payload) {
    return EventType.EMPTY;
  }
  const keys: string[] = Object.keys(payload);
  if (keys.length === 0) {
    return EventType.EMPTY;
  }
  if (keys.length === 1 && keys[0] === 'loading') {
    return EventType.LOADING;
  }
  if (keys.includes('isLocked') && payload.isLocked === true) {
    return EventType.LOCKED;
  }
  return EventType.STANDARD;
}
