import { Standard } from '@getvim/vim-connect';
import { insurer } from '../common';

export const patientInsurance: Standard.Entities.PatientInsurance = {
  memberId: '',
  ehrInsurance: '',
  groupId: '',
  insurer: insurer,
  payerId: '',
  externalMrn: '',
};
