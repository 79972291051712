import { getUpdateEncounterPayload } from './update-encounter';
import { getUpdateReferralPayload } from './update-referral';
import { getReferralAllConfig } from './config/referralAllConfig';
import {
  getDiSelectProviderPayload,
  getLabsSelectProviderPayload,
  getReferralSelectProviderPayload,
} from './ui-injection';
export * from './update-encounter';

export const getWritebackPayload: any = {
  updateEncounter: getUpdateEncounterPayload,
  updateReferral: getUpdateReferralPayload,
  getReferralAllConfig: getReferralAllConfig,
};

export const getUIInjectionPayload: any = {
  diSelectProvider: getDiSelectProviderPayload,
  labsSelectProvider: getLabsSelectProviderPayload,
  referralSelectProvider: getReferralSelectProviderPayload,
};
