import { Standard } from '@getvim/vim-connect';
import { patient } from './templates';

type FindPatientAction =
  Standard.Actions.ActionsStandard[Standard.Actions.ActionNames.FIND_PATIENT];

export const findPatient: {
  input: FindPatientAction['input'];
} = {
  input: patient,
};
