import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useLocalStorage from 'react-localstorage-hook';
import { Standard } from '@getvim/vim-connect';
import { isEmpty } from 'lodash-es';
import { PatientInContext } from '../../mocks/standard-events';
import { FlexBox } from '../common/FlexBox';
import { MonacoEditor } from '../monaco-editor';

export const PatientInContextTab = () => {
  const [payload, setPayload] = useState<Standard.Events.PatientInContextPayload>();
  const [predefinedPayload, setPredefinedPayload] = useState<string>('');
  const [lastUsedPayload, setLastUsedPayload] = useLocalStorage('lastPatientInContextPayload', '');
  const [, setShouldPublish] = useLocalStorage('shouldPublishPatientInContext', false);

  useEffect(() => {
    handlePredefinedPayloadChange({ target: { value: lastUsedPayload } } as SelectChangeEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShouldPublish(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePublish = () => setShouldPublish(true);

  const handlePredefinedPayloadChange = ({ target: { value } }: SelectChangeEvent) => {
    setPredefinedPayload(value);
    setLastUsedPayload(value);
    setPayload(PatientInContext.predefinedPayloads[value]);
  };

  return (
    <Accordion id="patientInContextTab">
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>patientInContext</Typography>
        <Select
          displayEmpty
          id="patient-in-context-select"
          value={predefinedPayload}
          size="small"
          onChange={handlePredefinedPayloadChange}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <MenuItem value="">Choose</MenuItem>
          {Object.keys(PatientInContext.predefinedPayloads).map((value) => (
            <MenuItem
              value={value}
              key={value}
              id={`patient-in-context-${value.replaceAll(' ', '-')}`}
            >
              {value}
            </MenuItem>
          ))}
        </Select>

        <Button
          id="publish-patient-in-context"
          disabled={isEmpty(predefinedPayload)}
          fullWidth
          onClick={(e) => {
            e.stopPropagation();
            handlePublish();
          }}
          variant="contained"
        >
          Publish
        </Button>
      </AccordionSummary>
      <AccordionDetails>
        <MonacoEditor onChange={setPayload} height="20rem" value={payload} />

        <FlexBox>
          <TextField
            id="patientInContextPayload"
            inputProps={{ 'data-testid': 'patientInContextPayload' }}
            variant="outlined"
            size="small"
            value={JSON.stringify(payload) || ''}
            sx={{ display: 'none' }}
          />
        </FlexBox>
      </AccordionDetails>
    </Accordion>
  );
};
