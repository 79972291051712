import { v4 as uuid } from 'uuid';
import { OSLog } from '@getvim/vim-connect-app';

export function createLog(
  parentId: string,
  operationType: string,
  type: string,
  startTime: number,
): OSLog {
  return {
    id: uuid(),
    parentId,
    createTime: Date.now(),
    operationType,
    type,
    status: false,
    startTime,
    isAutoTest: false,
  };
}
