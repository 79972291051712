import {
  JSXElementConstructor,
  ReactElement,
  SyntheticEvent,
  MouseEvent,
  DragEvent,
  ReactNode,
  ChangeEvent,
} from 'react';
import { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import { Standard } from '@getvim/vim-connect';
import {
  AdapterName,
  DisplayMode,
  OSSimulatorData,
  OSAdapterInfo,
  OSSettings,
  OSLog,
  OSLogs,
  OSReport,
  OSReports,
  OSAction,
  OSWriteback,
  OSStateData,
} from '@getvim/vim-connect-app';
import { HiddenJsonType } from './operations';
import { TabType } from './tabs';

export interface SelectorProps<T> extends SelectProps<T> {
  children: ReactNode;
  onChange: (e: SelectChangeEvent<T>, child: ReactNode) => void;
}

export type TabsMenuProps = {
  tabId: number;
  widgetExpanded: boolean;
  isAuthMonitorRunning: boolean;
  onTabChange: (e: SyntheticEvent, newTabId: number) => void;
};

export type TabItem = {
  name: string;
  icon: ReactElement<any, string | JSXElementConstructor<any>>;
  disabled?: boolean;
  iconOnly?: boolean;
};

export interface TabPanelProps {
  name: TabType;
  children?: ReactNode;
  index: number;
  value: number;
}

export type HeaderProps = {
  isDevelopmentMode: boolean;
  adapterInfo?: OSAdapterInfo;
  eventsCount: number;
  widgetExpanded: boolean;
  onChangeWidgetExpanded: () => void;
  onDragStart: (e: DragEvent<HTMLDivElement>) => void;
  onDrag: (e: DragEvent<HTMLDivElement>) => void;
};

export type ActionsProps = {
  actionData: OSAction | undefined;
  actionResponse: any;
  localLogs: OSLog[];
  onLogsUpdate: (logs: OSLog[]) => void;
  onActionDataChange: (actionData: OSAction) => void;
};

export type ConnectorsProps = {
  connectorsResponse?: any;
  windowHandlesTrack: any[];
  onClearAllHighlighterEvents: (e: MouseEvent<HTMLButtonElement>) => void;
};

export type EventsProps = {
  refs: Map<any, any>;
  events: Array<any>;
  writebacksData: Record<string, OSWriteback> | undefined;
  filteredEvents: Array<any>;
  filterText: string;
  searchType: string;
  localLogs: OSLog[];
  onClearAllEvents: () => void;
  onClearFilterText: () => void;
  onFilterTextChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onSearchTypeChange: (e: SelectChangeEvent) => void;
  onFilterEventsClick: (e: MouseEvent<HTMLButtonElement>) => void;
  onDeleteEventClick: (e: MouseEvent<HTMLButtonElement>) => void;
  onLogsUpdate: (logs: OSLog[]) => void;
  onScrollTopEventClick: () => void;
  onWritebackDataChange: (writebackData: OSWriteback) => void;
};

export type StatesProps = {
  states: Array<OSStateData>;
  isStateConfidentFilter?: boolean;
  onSetStateConfidentFilterClick: (value: boolean) => void;
};

export type StateProps = {
  state: OSStateData;
};

export type LogsProps = {
  logs?: OSLogs;
  onClearAllLogs: (e: MouseEvent<HTMLButtonElement>) => void;
};

export type LogProps = {
  log: OSLog;
};

export type ReportProps = {
  report: OSReport;
};

export type ReportsProps = {
  reports?: OSReports;
  reportNames: string[];
};

export type RunMonitors = {
  value: boolean;
  setIsAuth: boolean;
  sendData: boolean;
  setBoth: boolean;
  startupAuthMonitors?: {
    value: boolean;
  };
};

export type SettingsProps = {
  settings?: OSSettings;
  adapterInfo?: OSAdapterInfo;
  displayMode: DisplayMode;
  capabilitiesLastUpdate?: Date;
  adapterKPILastUpdate?: Date;
  startupAuthMonitors?: boolean;
  onRunMonitorsClick: (runMonitors: RunMonitors) => void;
  onDisplayModeChange: (displayMode: DisplayMode) => void;
  onOrganizationConfigChange: (updatedOrganizationConfig: any) => void;
};

export type TextCountProps = {
  id: string;
  className?: string;
  containerClassName?: string;
  label: string;
  max?: number;
  value: number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
};

export enum LoaderSide {
  RIGHT = 'right',
  LEFT = 'left',
}

export type ButtonLoaderProps = {
  id: string;
  title?: string;
  className: string;
  containerClassName?: string;
  isLoading?: boolean;
  disabled?: boolean;
  disableOnLoading?: boolean;
  disableLoader?: boolean;
  loaderSide?: LoaderSide;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
};

export type ButtonIconProps = {
  id: string;
  className: string;
  icon: ReactElement<any, string | JSXElementConstructor<any>>;
  disabled?: boolean;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
};

export interface JsonEditorProps {
  value: typeof Standard.Events | undefined;
  height: number;
  ignoreInvalidJsonOnChange?: boolean;
  onChange?: (newValue: string) => void;
}

export type JsonViewerProps = {
  id: string;
  type: HiddenJsonType;
  value: any;
  collapsed: number;
};

export type HeaderWarningProps = {
  isAuthMonitorRunning: boolean;
  adapterName?: AdapterName | undefined;
  settings?: OSSettings;
  onNavigateToTab: (tabName: TabType) => void;
  onRunMonitorsClick: (runMonitors: RunMonitors) => void;
};

export type HiddenDataProps = {
  osSimulatorData?: OSSimulatorData;
  events: Array<any>;
  filteredEvents: Array<any>;
  filterText: string;
  onNavigateToTab: (tabName: TabType) => void;
};
