import React, { RefObject, useState, useEffect, useRef } from 'react';
import { registerWidget } from '@getvim/utils-vim-connect-communication';
import {
  SendDataToWidgetType,
  AutomationOperationType,
  WritebackVersion,
} from '@getvim/vim-connect-app';
import { AutomationStatus } from '../../../types';
import { sendAutomationData, sendAutomationV2Data } from '../../../services';

export const Automations = () => {
  const automationOperationTypeRef: RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);
  const automationOperationSubTypeRef: RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);
  const automationPayloadRef: RefObject<HTMLTextAreaElement> = useRef<HTMLTextAreaElement>(null);
  const automationPayloadConfigRef: RefObject<HTMLTextAreaElement> =
    useRef<HTMLTextAreaElement>(null);
  const [automationStatus, setAutomationStatus] = useState<string>(AutomationStatus.INITIATE);
  const [automationResponse, setAutomationResponse] = useState<string>('');
  const [automationStartTime, setAutomationStartTime] = useState<number | undefined>(undefined);
  const [automationTotalTime, setAutomationTotalTime] = useState<string>('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleTestEnd = (status: AutomationStatus, value: string): void => {
    setAutomationStatus(status);
    setAutomationResponse(value);
    setAutomationTotalTime(`${Date.now() - automationStartTime!}`);
  };

  useEffect((): void => {
    registerWidget((payload: any) => {
      switch (payload.type) {
        case SendDataToWidgetType.AUTOMATION_RESPONSE: {
          handleTestEnd(AutomationStatus.COMPLETE, JSON.stringify(payload.data));
          break;
        }
        case SendDataToWidgetType.AUTOMATION_RESPONSE_V2: {
          handleTestEnd(AutomationStatus.COMPLETE, JSON.stringify(payload.data));
          break;
        }
      }
    });
  }, [handleTestEnd]);

  const handleTestStart = (): void => {
    setAutomationStatus(AutomationStatus.RUNNING);
    setAutomationStartTime(Date.now());
    setAutomationResponse('');
    setAutomationTotalTime('');
  };

  const handleRunClick = (
    _e: React.MouseEvent<HTMLButtonElement>,
    version: WritebackVersion,
  ): void => {
    handleTestStart();
    const automationOperationType: AutomationOperationType = automationOperationTypeRef?.current
      ?.value as AutomationOperationType;
    if (
      !Object.values(AutomationOperationType).includes(
        automationOperationType as AutomationOperationType,
      )
    ) {
      handleTestEnd(AutomationStatus.ERROR, `Invalid operation type: ${automationOperationType}`);
      return;
    }
    const operationSubType: string | undefined = automationOperationSubTypeRef?.current?.value;
    let payload: string | undefined = automationPayloadRef?.current?.value;
    if (payload) {
      try {
        payload = payload.replace(/”/g, '"').replace(/“/g, '"');
        payload = JSON.parse(JSON.stringify(JSON.parse(payload), null, 0));
      } catch (error: any) {
        handleTestEnd(AutomationStatus.ERROR, `Payload Error: ${error?.message}`);
        return;
      }
    }
    let payloadConfig: string | undefined = automationPayloadConfigRef?.current?.value;
    if (payloadConfig) {
      try {
        payloadConfig = payloadConfig.replace(/”/g, '"').replace(/“/g, '"');
        payloadConfig = JSON.parse(JSON.stringify(JSON.parse(payloadConfig), null, 0));
      } catch (error: any) {
        handleTestEnd(AutomationStatus.ERROR, `Payload Config Error: ${error?.message}`);
        return;
      }
    }
    switch (version) {
      case WritebackVersion.V1:
        sendAutomationData({
          operationType: automationOperationType!,
          operationSubType,
          payload,
          payloadConfig,
        });
        break;
      case WritebackVersion.V2:
        sendAutomationV2Data({
          operationType: automationOperationType!,
          operationSubType,
          payload,
          payloadConfig,
        });
        break;
    }
  };

  const handleClearAllClick = (): void => {
    setAutomationStatus(AutomationStatus.INITIATE);
    setAutomationResponse('');
    setAutomationStartTime(undefined);
    setAutomationTotalTime('');
    automationOperationTypeRef!.current!.value = '';
    automationOperationSubTypeRef!.current!.value = '';
    automationPayloadRef!.current!.value = '';
  };

  return (
    <div className="automations">
      <div className="input-container">
        <div className="label">Operation Type:</div>
        <div className="input">
          <input
            id="automation-operation-type"
            type="text"
            autoComplete="off"
            ref={automationOperationTypeRef}
          />
        </div>
      </div>
      <div className="input-container">
        <div className="label">Operation Sub Type:</div>
        <div className="input">
          <input
            id="automation-operation-sub-type"
            type="text"
            autoComplete="off"
            ref={automationOperationSubTypeRef}
          />
        </div>
      </div>
      <div className="input-container">
        <div className="label">Config:</div>
        <div className="input">
          <textarea
            id="automation-payload-config"
            autoComplete="off"
            ref={automationPayloadConfigRef}
          />
        </div>
      </div>
      <div className="input-container">
        <div className="label">Payload:</div>
        <div className="input">
          <textarea id="automation-payload" autoComplete="off" ref={automationPayloadRef} />
        </div>
      </div>
      <div className="input-container">
        <div className="label"></div>
        <div className="input">
          <div className="buttons">
            <button
              id="automation-run-button"
              className="button mini"
              defaultValue=""
              onClick={(e) => handleRunClick(e, WritebackVersion.V1)}
            >
              Run V1
            </button>
            <button
              id="automation-run-button-v2"
              className="button mini"
              defaultValue=""
              onClick={(e) => handleRunClick(e, WritebackVersion.V2)}
            >
              Run V2
            </button>
            <button
              id="automation-clear-all-button"
              className="button mini"
              defaultValue=""
              onClick={handleClearAllClick}
            >
              Clear All
            </button>
          </div>
        </div>
      </div>
      <div className="input-container">
        <div className="label">Status:</div>
        <div className="input">
          <input
            id="automation-request-status"
            type="text"
            autoComplete="off"
            readOnly
            value={automationStatus}
          />
        </div>
      </div>
      <div className="input-container">
        <div className="label">Response:</div>
        <div className="input">
          <input
            id="automation-response"
            type="text"
            autoComplete="off"
            readOnly
            value={automationResponse}
          />
        </div>
      </div>
      <div className="input-container">
        <div className="label">Total Time:</div>
        <div className="input">
          <input
            id="automation-total-time"
            type="text"
            autoComplete="off"
            readOnly
            value={automationTotalTime}
          />
        </div>
      </div>
    </div>
  );
};

Automations.displayName = 'Automations';
