import React, { forwardRef, useState, useEffect, useRef, MutableRefObject } from 'react';
import { Loader } from '@getvim/atomic-ui';
import {
  getHeadBoxClass,
  getVersionClass,
  getLoadingBoxClass,
  getLogoName,
} from '../../../services';
import { HeaderProps, ICONS_MAP } from '../../../types';

export const Header = forwardRef<HTMLDivElement, HeaderProps>(
  (
    {
      isDevelopmentMode,
      adapterInfo,
      eventsCount,
      widgetExpanded,
      onChangeWidgetExpanded,
      onDragStart,
      onDrag,
    },
    ref,
  ) => {
    const prevEventsCount: MutableRefObject<number | null> = useRef<number | null>(null);
    const [counterUpdated, setCounterUpdated] = useState<boolean>(false);

    useEffect((): void => {
      setCounterUpdated(
        !widgetExpanded && eventsCount > 0 && eventsCount !== prevEventsCount.current,
      );
      prevEventsCount.current = eventsCount;
    }, [eventsCount, widgetExpanded]);

    return (
      <div className="header">
        <div className="head-box title">
          <div className="logo-container">
            <Loader size="small" padding="none" />
          </div>
          <p className="simulator-title">
            <span>OS Simulator</span>
          </p>
        </div>
        <div className={getHeadBoxClass(adapterInfo?.adapterName, 'mini')}>
          <div className={`ehr ${getLogoName(adapterInfo?.adapterName)}-logo`}></div>
          <p>{adapterInfo?.adapterName}</p>
        </div>
        <div className={getHeadBoxClass(adapterInfo?.ehrVersion, 'mini version')}>
          <p className={getVersionClass(adapterInfo?.ehrVersion)}>{adapterInfo?.ehrVersion}</p>
        </div>
        <div id="vimEnv" className={getHeadBoxClass(adapterInfo?.environment, 'environment')}>
          <p>{adapterInfo?.environment}</p>
        </div>
        <div
          id="eventCounter"
          className={getHeadBoxClass(
            eventsCount.toString(),
            `counter${counterUpdated ? ' news' : ''}`,
          )}
        >
          <p>{eventsCount}</p>
        </div>
        {isDevelopmentMode && (
          <div className="head-box development">
            <p>D</p>
          </div>
        )}
        <div id="header-loading" className={getLoadingBoxClass(adapterInfo?.adapterName)}>
          <div className="loading-text"></div>
        </div>
        <div
          ref={ref}
          className="position-widget-container"
          draggable
          onDragStart={onDragStart}
          onDrag={onDrag}
        >
          {ICONS_MAP['open-with']}
        </div>
        <div
          className="expand-widget-container"
          onKeyDown={onChangeWidgetExpanded}
          onClick={onChangeWidgetExpanded}
          aria-hidden="true"
        >
          {ICONS_MAP[`expand-${widgetExpanded ? 'more' : 'less'}`]}
        </div>
      </div>
    );
  },
);

Header.displayName = 'Header';
