import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Switch } from '@mui/material';
import { OSStateData } from '@getvim/vim-connect-app';
import { StatesProps } from '../../../types';
import { addLeadingZero, getItemId, sendSetStateConfidentFilterData } from '../../../services';
import { State } from '../state';

export const States: FC<StatesProps> = ({
  states,
  isStateConfidentFilter = false,
  onSetStateConfidentFilterClick,
}) => {
  const [isConfidentFilterLocal, setIsStateConfidentFilterLocal] = useState<boolean>(false);

  useEffect((): void => {
    setIsStateConfidentFilterLocal(isStateConfidentFilter);
  }, [isStateConfidentFilter]);

  const handleStateConfidentFilterSwitch = (e: ChangeEvent<HTMLInputElement>) => {
    onSetStateConfidentFilterClick(e.target.checked);
    setIsStateConfidentFilterLocal(e.target.checked);
    sendSetStateConfidentFilterData(e.target.checked);
  };

  const filteredStates: OSStateData[] = isConfidentFilterLocal
    ? states.filter((state: OSStateData) => state.data.confidence === 1)
    : states;

  return (
    <>
      <div className="states">
        <div className="confident-filter">
          <div className="label">Show confident 1 only</div>
          <div className="switcher">
            <Switch
              checked={isConfidentFilterLocal}
              onChange={handleStateConfidentFilterSwitch}
              color="warning"
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </div>
        </div>
      </div>
      <div className="text-divider">States ({addLeadingZero(filteredStates.length)})</div>
      {filteredStates.map((state: OSStateData) => (
        <State key={getItemId(state.id, state.receivedDate)} state={state} />
      ))}
    </>
  );
};

States.displayName = 'States';
