import React from 'react';
import { ICONS_MAP, WarningType } from '../../../types';

export const WritebackWarning = () => {
  return (
    <div className="header-warning-container">
      <div className={`header-warning-body ${WarningType.WARNING}`}>
        {ICONS_MAP['warning']} {'Encounter is locked'} {ICONS_MAP['warning']}
      </div>
    </div>
  );
};

WritebackWarning.displayName = 'WritebackWarning';
