import { Insurer } from '@getvim-os/standard';
import { Standard } from '@getvim/vim-connect';

const demographics: Standard.Entities.PatientDemographics = {
  firstName: 'KEYCHANGE',
  lastName: 'TEST',
  dateOfBirth: '1960-08-01',
  gender: Standard.Entities.Gender.male,
  address: {
    fullAddress: '1699 HARWOOD ST, ORLANDO, FL, 32803',
    address1: '1699 HARWOOD ST',
    city: 'ORLANDO',
    state: 'FL',
    zipCode: '32803',
  },
};

const insurance: Standard.Entities.PatientInsurance = {
  memberId: '907617712',
  ehrInsurance: 'UNITED HEALTHCARE - BEST BUY',
  insurer: Insurer.UHC,
};

const contact_info: Standard.Entities.PatientContactInfo = {
  homePhoneNumber: '(999) 999-999999',
};

const patientId: string = '7577';

export const keychangeTest: Standard.Events.PatientInContextPayload = {
  patientId,
  demographics,
  insurance,
  contact_info,
};
