import { Standard } from '@getvim/vim-connect';
import {
  minnieMouse,
  natashaDavies,
  stevenVazquez,
  tonyStark,
  rachelGreen,
  thomasShelby,
  gustavoFring,
  patriciaBell,
  catherineJohnson,
  maxDale,
  keychangeTest,
  judithFleming,
  dummyPatient,
  pepperPotts,
  ronrxOrdy,
  orrxPete,
  ryanThompson,
} from '../patients';

export const predefinedPayloads: Record<string, Standard.Events.PatientInContextPayload> = {
  'Minnie Mouse': minnieMouse,
  'Steven Vazquez': stevenVazquez,
  'Natasha Davies': natashaDavies,
  'Patricia Bell': patriciaBell,
  'Catherine Johnson': catherineJohnson,
  'Max Dale': maxDale,
  'Tony Stark': tonyStark,
  'Dummy Patient': dummyPatient,
  'Rachel Green': rachelGreen,
  'Thomas Shelby': thomasShelby,
  'Gustavo Fring': gustavoFring,
  'Keychange Test': keychangeTest,
  'Judith Fleming': judithFleming,
  'Pepper Potts': pepperPotts,
  'Ronrx Ordy': ronrxOrdy,
  'Orrx Pete': orrxPete,
  'Ryan Thompson': ryanThompson,
};
