import { AutomationOperationType } from '@getvim/vim-connect-app';

export enum UpdateEncounterType {
  WRITEBACK_SOAP = 'writeback soap',
  WRITEBACK_ASSESSMENT = 'writeback assessment',
  UPDATE_PROCEDURES = 'update procedures',
  UPDATE_OBJECTIVE = 'update objective',
  UPDATE_SUBJECTIVE = 'update subjective',
  UPDATE_ASSESSMENT_GN = 'update assessment general notes',
  UPDATE_PLAN_GN = 'update plan general notes',
  UPDATE_PATIENT_INSTRUCTIONS_GN = 'update patient instructions notes',
}

export enum UpdateReferralType {
  REFERRAL_ALL = 'referral all',
}

export enum ConfigType {
  REFERRAL_ALL_CONFIG = 'referral all config',
}

export enum UIInjectionType {
  DI_SELECT_PROVIDER = 'diSelectProvider',
  LABS_SELECT_PROVIDER = 'labsSelectProvider',
  REFERRAL_SELECT_PROVIDER = 'referralSelectProvider',
}

export const OPERATIONS_MAP = {
  updateEncounter: Object.values(UpdateEncounterType),
  updateReferral: Object.values(UpdateReferralType),
  diSelectProvider: [UIInjectionType.DI_SELECT_PROVIDER],
  labsSelectProvider: [UIInjectionType.LABS_SELECT_PROVIDER],
  referralSelectProvider: [UIInjectionType.REFERRAL_SELECT_PROVIDER],
};

export enum OperationSubTypeMap {
  updateEncounter = 'updateEncounter',
  updateReferral = 'updateReferral',
  diSelectProvider = 'diSelectProvider',
  labsSelectProvider = 'labsSelectProvider',
  referralSelectProvider = 'referralSelectProvider',
}

export enum OperationSubType {
  WRITEBACK_SOAP = UpdateEncounterType.WRITEBACK_SOAP,
  WRITEBACK_ASSESSMENT = UpdateEncounterType.WRITEBACK_ASSESSMENT,
  UPDATE_PROCEDURES = UpdateEncounterType.UPDATE_PROCEDURES,
  UPDATE_OBJECTIVE = UpdateEncounterType.UPDATE_OBJECTIVE,
  UPDATE_SUBJECTIVE = UpdateEncounterType.UPDATE_SUBJECTIVE,
  UPDATE_ASSESSMENT_GN = UpdateEncounterType.UPDATE_ASSESSMENT_GN,
  UPDATE_PLAN_GN = UpdateEncounterType.UPDATE_PLAN_GN,
  UPDATE_PATIENT_INSTRUCTIONS_GN = UpdateEncounterType.UPDATE_PATIENT_INSTRUCTIONS_GN,
  REFERRAL_ALL = UpdateReferralType.REFERRAL_ALL,
  DI_SELECT_PROVIDER = UIInjectionType.DI_SELECT_PROVIDER,
  LABS_SELECT_PROVIDER = UIInjectionType.LABS_SELECT_PROVIDER,
  REFERRAL_SELECT_PROVIDER = UIInjectionType.REFERRAL_SELECT_PROVIDER,
}

export const LOG_TO_SHORT = {
  [OperationSubType.WRITEBACK_SOAP]: 'wso',
  [OperationSubType.WRITEBACK_ASSESSMENT]: 'wba',
  [OperationSubType.UPDATE_PROCEDURES]: 'up',
  [OperationSubType.UPDATE_OBJECTIVE]: 'uob',
  [OperationSubType.UPDATE_SUBJECTIVE]: 'uos',
  [OperationSubType.UPDATE_ASSESSMENT_GN]: 'uagn',
  [OperationSubType.UPDATE_PLAN_GN]: 'upgn',
  [OperationSubType.UPDATE_PATIENT_INSTRUCTIONS_GN]: 'upin',
  [OperationSubType.REFERRAL_ALL]: 'ra',
  [UIInjectionType.DI_SELECT_PROVIDER]: 'dsp',
  [UIInjectionType.LABS_SELECT_PROVIDER]: 'lsp',
  [UIInjectionType.REFERRAL_SELECT_PROVIDER]: 'rsp',
};

export const SHORT_TO_LOG: {
  [k: string]: string;
} = Object.fromEntries(Object.entries(LOG_TO_SHORT).map(([key, value]) => [value, key]));

export const SHORT_TO_OPERATION_TYPE: {
  a: string;
  w: string;
  u: string;
} = {
  a: AutomationOperationType.ACTION,
  w: AutomationOperationType.WRITEBACK,
  u: AutomationOperationType.UI_INJECTION,
};

export const OPERATION_TYPE_TO_SHORT: {
  [k: string]: string;
} = Object.fromEntries(Object.entries(SHORT_TO_OPERATION_TYPE).map(([key, value]) => [value, key]));

export enum HiddenJsonType {
  EVENT = 'event',
  STATE = 'state',
  REPORT = 'report',
  CAPABILITIES = 'capabilities',
  WRITEBACK = 'writeback',
}

export const OPERATION_TYPE_LIST = ['ALL', ...Object.keys(AutomationOperationType)];
