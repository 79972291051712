import { Insurer } from '@getvim-os/standard';
import { Standard } from '@getvim/vim-connect';

const demographics: Standard.Entities.PatientDemographics = {
  firstName: 'Steven',
  lastName: 'Vazquez',
  dateOfBirth: '1949-05-07',
  gender: Standard.Entities.Gender.male,
  address: {
    fullAddress: '8826 Wendy Ln S, ROYAL PALM BEACH, FL, 33411',
    address1: '8826 Wendy Ln S',
    city: 'ROYAL PALM BEACH',
    state: 'FL',
    zipCode: '33411',
  },
};

const insurance: Standard.Entities.PatientInsurance = {
  memberId: '4093651035',
  groupId: '12345',
  ehrInsurance: 'Test',
  insurer: Insurer.UHC,
};

const contact_info: Standard.Entities.PatientContactInfo = {
  homePhoneNumber: '415-347-5430',
  email: 'h@getvim.com',
};

const pcp: Standard.Entities.Provider = {
  providerEhrId: '40598',
  npi: '1285171983',
  demographics: {
    firstName: 'SOLANGE',
    lastName: 'BAVUSO',
  },
  specialty: [
    {
      description: 'Nurse Practitioner',
    },
  ],
};

const problemList: Standard.Entities.Diagnosis[] = [
  {
    description: '(Induced) termination of pregnancy with other complications',
    code: 'O04.89',
  },
  { description: 'Irritable bowel disease', code: 'K58.9' },
];

const patientId: string = '135214';

export const stevenVazquez: Standard.Events.PatientInContextPayload = {
  patientId,
  demographics,
  insurance,
  contact_info,
  pcp,
  problemList,
};
