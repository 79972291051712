import React, { FC } from 'react';
import {
  EncounterStartTab,
  LogoutTab,
  OnLoggedInTab,
  PatientInContextTab,
  PatientOutOfContextTab,
  ReferralViewedTab,
  EncounterUpdatedTab,
  EncounterExitTab,
  ReferralClosedTab,
  ReferralSavedTab,
  ReferralDeletedTab,
  OrderCreatedTab,
  OrderViewedTab,
  OrderClosedTab,
  OrdersViewedTab,
  OrdersClosedTab,
} from '.';

export const StandartEventsAccordion: FC = () => (
  <>
    <OnLoggedInTab />
    <LogoutTab />
    <PatientInContextTab />
    <PatientOutOfContextTab />
    <EncounterStartTab />
    <EncounterUpdatedTab />
    <EncounterExitTab />
    <ReferralViewedTab />
    <ReferralSavedTab />
    <ReferralClosedTab />
    <ReferralDeletedTab />
    <OrderCreatedTab />
    <OrderViewedTab />
    <OrderClosedTab />
    <OrdersViewedTab />
    <OrdersClosedTab />
  </>
);
