import { Standard } from '@getvim/vim-connect';

export const predefinedPayloads: Record<string, Standard.Events.OnLoggedInPayload> = {
  'simulator@getvim.com': {
    username: 'simulator@getvim.com',
    password: '12345',
    authToken: '[bitwarden: seamless auth token]',
  },
  'jake@getvim.com': {
    username: 'jake@getvim.com',
    password: '12345',
    authToken: '[bitwarden: seamless auth token]',
  },
};
