export const getLoadingBoxClass = (value: string | undefined): string => {
  return `${`head-box loading${value ? ' hidden' : ''}`}`;
};

export const getHeadBoxClass = (value: string | undefined, className: string): string => {
  return `${`head-box ${value ? className : 'hidden'}`}`;
};

export const getVersionClass = (ehrVersion: string | undefined): string => {
  return ehrVersion?.startsWith('getEhrVersion') ? 'not-implemented' : 'version';
};

export const getLogoName = (value: string | undefined): string | undefined => {
  return value?.toLowerCase().replaceAll(' ', '-').replaceAll('_', '-');
};
