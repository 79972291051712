import { Standard } from '@getvim/vim-connect/build';

export const mickAbae: Standard.Entities.Provider = {
  providerEhrId: '66417',
  npi: '1881677680',
  demographics: {
    firstName: 'Mick',
    lastName: 'Abae',
  },
  specialty: [
    {
      description: 'Reproductive Endocrinology/Infertility',
    },
  ],
};
