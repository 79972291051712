import { Insurer } from '@getvim-os/standard';
import { Standard } from '@getvim/vim-connect';

const demographics: Standard.Entities.PatientDemographics = {
  firstName: 'Rachel',
  lastName: 'Green',
  dateOfBirth: '1993-04-11',
  gender: Standard.Entities.Gender.female,
  address: {
    fullAddress: '545 Grove St, Apartment 20, New York, NY, 10001',
    address1: '545 Grove St',
    address2: 'Apartment 20',
    city: 'New York',
    state: 'NY',
    zipCode: '10001',
  },
};

const insurance: Standard.Entities.PatientInsurance = {
  memberId: '9999999',
  groupId: '',
  payerId: '',
  ehrInsurance: 'Test',
  insurer: Insurer.UHC,
};

const contact_info: Standard.Entities.PatientContactInfo = {
  homePhoneNumber: '539-202-1246',
  mobilePhoneNumber: '539-202-1246',
  email: 'rachel@somewhere.com',
};

const pcp: Standard.Entities.Provider = {
  providerEhrId: '40598',
  npi: '1285171983',
  demographics: {
    firstName: 'SOLANGE',
    lastName: 'BAVUSO',
  },
  specialty: [
    {
      description: 'Nurse Practitioner',
    },
  ],
};

const problemList: Standard.Entities.Diagnosis[] = [
  {
    description: '(Induced) termination of pregnancy with other complications',
    code: 'O04.89',
  },
  { description: 'Irritable bowel disease', code: 'K58.9' },
];

const patientId: string = '136330';

export const rachelGreen: Standard.Events.PatientInContextPayload = {
  patientId,
  demographics,
  insurance,
  contact_info,
  pcp,
  problemList,
};
