import { Standard } from '@getvim/vim-connect';

type GetProviderDailySummaryAction =
  Standard.Actions.ActionsStandard[Standard.Actions.ActionNames.GET_PROVIDER_DAILY_SUMMARY];

export const getProviderDailySummary: {
  input: GetProviderDailySummaryAction['input'];
} = {
  input: undefined,
};
