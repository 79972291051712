import React, { FC } from 'react';
import { VimConnectUIAccordion } from '.';
import { Box } from '@mui/material';
import { Header } from '../layout/Header';

export const VimConnectUIMain: FC = () => (
  <Box className="accordion-wrapper">
    <Header title="Vim Connect UI Events" />
    <VimConnectUIAccordion />
  </Box>
);
