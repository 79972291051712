import React, { useCallback, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useLocalStorage from 'react-localstorage-hook';
import { EventType } from '@getvim/utils-vim-connect-communication';
import { isEmpty } from 'lodash-es';
import { VCHubSendToNotifications } from '../../mocks/vim-connect-ui-events';
import { FlexBox } from '../common/FlexBox';
import { MonacoEditor } from '../monaco-editor';
import { handlePublish } from './utils';

export const VCHubSendToNotificationsTab = () => {
  const [payload, setPayload] = useState<any>();
  const [predefinedPayload, setPredefinedPayload] = useState<string>('');
  const [lastUsedPayload, setLastUsedPayload] = useLocalStorage(
    'lastVCHubSendToNotificationsPayload',
    '',
  );

  const handlePredefinedPayloadChange = useCallback(
    ({ target: { value } }: SelectChangeEvent) => {
      setPredefinedPayload(value);
      setLastUsedPayload(value);
      setPayload(VCHubSendToNotifications.predefinedPayloads[value]);
    },
    [setLastUsedPayload],
  );

  useEffect(() => {
    handlePredefinedPayloadChange({ target: { value: lastUsedPayload } } as SelectChangeEvent);
  }, [handlePredefinedPayloadChange, lastUsedPayload]);

  return (
    <Accordion id="vcHubSendToNotificationsTab">
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>VCHubSendToNotifications</Typography>
        <Select
          id="select-vc-hub-send-to-notifications"
          displayEmpty
          value={predefinedPayload}
          size="small"
          onChange={handlePredefinedPayloadChange}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <MenuItem value="">Choose</MenuItem>
          {Object.keys(VCHubSendToNotifications.predefinedPayloads).map((value, index) => (
            <MenuItem id={`select-vc-hub-send-to-notifications-${index}`} value={value} key={value}>
              {value}
            </MenuItem>
          ))}
        </Select>

        <Button
          id="publish-vc-hub-send-to-notifications"
          disabled={isEmpty(predefinedPayload)}
          fullWidth
          onClick={(e) => {
            e.stopPropagation();
            handlePublish(payload, EventType.VCHubSendToNotifications);
          }}
          variant="contained"
        >
          Publish
        </Button>
      </AccordionSummary>
      <AccordionDetails>
        <MonacoEditor onChange={setPayload} height="20rem" value={payload} />

        <FlexBox>
          <TextField
            id="displayErrorPayload"
            variant="outlined"
            size="small"
            value={JSON.stringify(payload)}
            sx={{ display: 'none' }}
          />
        </FlexBox>
      </AccordionDetails>
    </Accordion>
  );
};
