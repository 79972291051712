import { Insurer } from '@getvim-os/standard';
import { Standard } from '@getvim/vim-connect';

const demographics: Standard.Entities.PatientDemographics = {
  firstName: 'Natasha',
  lastName: 'Davies',
  dateOfBirth: '1972-10-10',
  gender: Standard.Entities.Gender.female,
  address: {
    fullAddress: 'FL',
    state: 'FL',
  },
};

const insurance: Standard.Entities.PatientInsurance = {
  memberId: '8931564589',
  ehrInsurance: 'Test',
  insurer: Insurer.UHC,
};

const contact_info: Standard.Entities.PatientContactInfo = {
  homePhoneNumber: '888-888-8888',
  email: 'test@test.com',
};

const problemList: Standard.Entities.Diagnosis[] = [
  {
    description: 'A-fib',
    code: 'I48.91',
  },
  { description: 'Type 2 diabetes mellitus with other specified complication', code: 'E11.69' },
];

const patientId: string = '135222';

export const natashaDavies: Standard.Events.PatientInContextPayload = {
  patientId,
  demographics,
  insurance,
  contact_info,
  problemList,
};
