import React, { useCallback, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useLocalStorage from 'react-localstorage-hook';
import { RuntimeContract } from '@getvim/vim-connect';
import { WidgetIncomingEvent } from '@getvim/vim-connect-app';
import { isEmpty } from 'lodash-es';
import { FlexBox } from '../common/FlexBox';
import { MonacoEditor } from '../monaco-editor';
import { handlePublish } from './utils';
import { SelectApplication } from '../../mocks/vim-connect-ui-events';

export const SelectApplicationTab = () => {
  const [payload, setPayload] = useState<RuntimeContract.Incoming.SelectAppPayload>();
  const [predefinedPayload, setPredefinedPayload] = useState<string>('');
  const [lastUsedPayload, setLastUsedPayload] = useLocalStorage('lastSelectApplicationPayload', '');

  const handlePredefinedPayloadChange = useCallback(
    ({ target: { value } }: SelectChangeEvent) => {
      setPredefinedPayload(value);
      setLastUsedPayload(value);
      setPayload(SelectApplication.predefinedPayloads[value]);
    },
    [setLastUsedPayload],
  );

  useEffect(() => {
    handlePredefinedPayloadChange({ target: { value: lastUsedPayload } } as SelectChangeEvent);
  }, [handlePredefinedPayloadChange, lastUsedPayload]);

  return (
    <Accordion id="selectApplicationTab">
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>SelectApplication</Typography>
        <Select
          id="select-select-application"
          displayEmpty
          value={predefinedPayload}
          size="small"
          onChange={handlePredefinedPayloadChange}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <MenuItem value="">Choose</MenuItem>
          {Object.keys(SelectApplication.predefinedPayloads).map((value, index) => (
            <MenuItem id={`select-select-application-${index}`} value={value} key={value}>
              {value}
            </MenuItem>
          ))}
        </Select>

        <Button
          id="publish-select-application"
          disabled={isEmpty(predefinedPayload)}
          fullWidth
          onClick={(e) => {
            e.stopPropagation();
            handlePublish(payload, WidgetIncomingEvent.SelectApplication);
          }}
          variant="contained"
        >
          Publish
        </Button>
      </AccordionSummary>
      <AccordionDetails>
        <MonacoEditor onChange={setPayload} height="20rem" value={payload} />

        <FlexBox>
          <TextField
            id="selectApplicationPayload"
            variant="outlined"
            size="small"
            value={JSON.stringify(payload)}
            sx={{ display: 'none' }}
          />
        </FlexBox>
      </AccordionDetails>
    </Accordion>
  );
};
