import { WritebackResponse } from '@getvim-adapter/api';

type ListItem = {
  isSuccess: boolean;
  value?: {
    code?: string;
    description?: string;
    note?: string;
    name?: string;
  };
  category?: any;
  subCategory?: any;
};

export function getWritebackResult(execution: WritebackResponse): boolean {
  for (const fieldKey in execution) {
    const fieldStatus = execution[fieldKey as keyof WritebackResponse];
    if (fieldStatus) {
      if ('list' in fieldStatus) {
        if (fieldStatus.list.some((field: ListItem) => field?.isSuccess)) {
          return true;
        }
      } else if ('isSuccess' in fieldStatus && fieldStatus.isSuccess) {
        return true;
      }
    }
  }
  return false;
}
