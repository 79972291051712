import React, { FC } from 'react';
import { isEmpty } from 'lodash-es';
import MonacoEditorReact from '@monaco-editor/react';
import { isDarkMode } from '../../../services';
import { JsonEditorProps } from '../../../types';

export const JsonEditor: FC<JsonEditorProps> = ({
  value,
  height,
  ignoreInvalidJsonOnChange = false,
  onChange = () => {},
}) => {
  const handleEditorChange = (newValue: any): void => {
    onChange(newValue ? JSON.parse(newValue) : '');
  };

  const handleEditorChangeIgnore = (newValue: any): void => {
    let parsedValue: any = '';
    try {
      parsedValue = newValue ? JSON.parse(newValue) : '';
      onChange(parsedValue);
    } catch {
      /* ignore invalid json error */
    }
  };

  return (
    <MonacoEditorReact
      onChange={ignoreInvalidJsonOnChange ? handleEditorChangeIgnore : handleEditorChange}
      height={`${height}rem`}
      value={isEmpty(value) ? undefined : JSON.stringify(value, undefined, 4)}
      defaultLanguage="json"
      theme={isDarkMode() ? 'vs-dark' : 'light'}
      options={{
        minimap: { enabled: false },
        formatOnPaste: true,
        formatOnType: true,
        scrollBeyondLastLine: true,
        trimAutoWhitespace: true,
        fontSize: 16,
        autoIndent: 'full',
      }}
    />
  );
};

JsonEditor.displayName = 'JsonEditor';
