import React, { MouseEvent, ForwardedRef, forwardRef, useRef, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { FilterAlt, Delete, Publish } from '@mui/icons-material';
import { JsonViewer } from '../common';
import { getDisplayTime, getEventType } from '../../../services';
import { ICONS_MAP, HiddenJsonType, EventType } from '../../../types';
import { Operations } from '../operations';

export const Event = forwardRef<any, any>(
  (
    {
      event,
      writebacksData,
      localLogs,
      onFilterEventsClick,
      onDeleteEventClick,
      onLogsUpdate,
      onScrollTopEventClick,
      onWritebackDataChange,
    },
    ref: ForwardedRef<any>,
  ) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const targetRef = useRef<HTMLElement | null>(null);
    const { id, eventName, payload, receivedDate, writebacks, uiInjections } = event;

    const handleExpandEvent = (e: MouseEvent<HTMLDivElement>): void => {
      const targetElement: HTMLElement = e.currentTarget as HTMLElement;
      if (!targetElement.classList.contains('adapter-event-title')) {
        return;
      }
      const classList: DOMTokenList | undefined = (e.target as any)?.parentNode?.classList;
      if (
        classList &&
        (classList.contains('event-filter') ||
          classList.contains('filter-event') ||
          classList.contains('delete-event') ||
          classList.contains('scroll-top-event'))
      ) {
        return;
      }
      if (!expanded) {
        setTimeout(() => {
          if (writebacks.length > 0 || uiInjections.length > 0) {
            targetRef.current?.scrollIntoView();
          }
        }, 200);
      }
      setExpanded(!expanded);
    };

    const { displayTime, timestamp } = getDisplayTime(receivedDate);
    const eventType: EventType = getEventType(payload);

    return (
      <Accordion className="event-wrap" data-id={id} data-name={eventName} expanded={expanded}>
        <AccordionSummary onClick={handleExpandEvent} className="adapter-event-title">
          <div className="event">
            <div className="event-box event-icon">{ICONS_MAP[eventName]}</div>
            <div className="event-box event-title">{eventName}</div>
            <div className={`event-box event-type ${eventType}`}>{eventType}</div>
            <div className="event-box event-display-time">{displayTime}</div>
            <div className="event-box event-timestamp">{timestamp}</div>
            <div className="event-box event-icon event-filter">
              <FilterAlt
                id={`${id}_filter_${eventName}`}
                className="filter-event"
                onClick={onFilterEventsClick}
              />
            </div>
            <div className="event-box event-icon event-delete">
              <Delete id={`${id}_delete`} className="delete-event" onClick={onDeleteEventClick} />
            </div>
            <div className="event-box event-icon scroll-top-event">
              <Publish className="scroll-top-event" onClick={onScrollTopEventClick} />
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails ref={targetRef}>
          <JsonViewer id={eventName} type={HiddenJsonType.EVENT} value={event} collapsed={0} />
          <Operations
            id={id}
            ref={ref}
            eventName={eventName}
            eventType={eventType}
            writebacksData={writebacksData}
            localLogs={localLogs}
            writebacks={writebacks}
            uiInjections={uiInjections}
            onLogsUpdate={onLogsUpdate}
            onWritebackDataChange={onWritebackDataChange}
          />
        </AccordionDetails>
      </Accordion>
    );
  },
);

Event.displayName = 'Event';
