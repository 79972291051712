import { Standard } from '@getvim/vim-connect';

type SearchProvidersAction =
  Standard.Actions.ActionsStandard[Standard.Actions.ActionNames.SEARCH_PROVIDERS];

export const searchProviders: {
  input: SearchProvidersAction['input'];
} = {
  input: {
    searchTerm: '',
  },
};
