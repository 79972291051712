import React, { FC } from 'react';
import { Button } from '@mui/material';
import { ButtonLoaderProps, LoaderSide } from '../../../types';

function createLoader(disableLoader: boolean, isLoading: boolean | undefined): false | JSX.Element {
  return (
    !disableLoader && (
      <div className="spinner-container">{isLoading && <div className="spinner"></div>}</div>
    )
  );
}

export const ButtonLoader: FC<ButtonLoaderProps> = ({
  id,
  title,
  className,
  containerClassName,
  isLoading,
  disabled,
  disableOnLoading = false,
  disableLoader = false,
  loaderSide = LoaderSide.RIGHT,
  onClick,
}) => {
  return (
    <div className={`run-button${containerClassName ? ` ${containerClassName}` : ''}`}>
      {loaderSide === LoaderSide.LEFT && createLoader(disableLoader, isLoading)}
      <div className="button-container">
        <Button
          id={id}
          className={className}
          onClick={onClick}
          variant="outlined"
          disabled={disableOnLoading ? disabled || isLoading : disabled}
        >
          {title ?? 'Run'}
        </Button>
      </div>
      {loaderSide === LoaderSide.RIGHT && createLoader(disableLoader, isLoading)}
    </div>
  );
};

ButtonLoader.displayName = 'ButtonLoader';
