import { Standard } from '@getvim/vim-connect';

export const pepperPotts: Standard.Events.PatientInContextPayload = {
  patientId: '999999',
  demographics: {
    firstName: 'Pepper',
    lastName: 'Potts',
    dateOfBirth: '1950-04-04',
  },
  insurance: {
    memberId: 'ABC_3746',
  },
};
