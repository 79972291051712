import { Insurer } from '@getvim-os/standard';
import { Standard } from '@getvim/vim-connect';

const demographics: Standard.Entities.PatientDemographics = {
  firstName: 'Minnie',
  lastName: 'Mouse',
  middleName: '',
  dateOfBirth: '1928-11-18',
  gender: Standard.Entities.Gender.male,
  address: {
    fullAddress: '6044 NORTH KEATING, CHICAGO, IL, 60646',
    address1: '6044 NORTH KEATING',
    address2: '',
    city: 'CHICAGO',
    state: 'IL',
    zipCode: '60646',
  },
};

const insurance: Standard.Entities.PatientInsurance = {
  memberId: '64664366873',
  ehrInsurance: 'Test',
  groupId: '12345',
  insurer: Insurer.UHC,
};

const contact_info: Standard.Entities.PatientContactInfo = {
  homePhoneNumber: '888-888-8888',
  email: 'nico@xyz.com',
};

const pcp: Standard.Entities.Provider = {
  providerEhrId: '66417',
  npi: '1881677680',
  demographics: {
    firstName: 'Mick',
    lastName: 'Abae',
  },
  specialty: [
    {
      description: 'Reproductive Endocrinology/Infertility',
    },
  ],
};

const problemList: Standard.Entities.Diagnosis[] = [
  { description: 'Type 2 diabetes mellitus with other specified complication', code: 'E11.69' },
  { description: 'Pure hyperglyceridemia', code: 'E78.1' },
];

const patientId: string = '134531';

export const minnieMouse: Standard.Events.PatientInContextPayload = {
  patientId,
  demographics,
  insurance,
  contact_info,
  pcp,
  problemList,
};
