import React, { FC } from 'react';
import { TextField, InputAdornment, MenuItem, FormControl } from '@mui/material';
import { Clear } from '@mui/icons-material';
import { Event } from '../event';
import { ButtonIcon, Selector } from '../common';
import { SEARCH_TYPE, EventsProps, ICONS_MAP } from '../../../types';
import { addLeadingZero, getItemId } from '../../../services';

export const Events: FC<EventsProps> = ({
  refs,
  events,
  writebacksData,
  filteredEvents,
  filterText,
  searchType,
  localLogs,
  onClearAllEvents,
  onClearFilterText,
  onFilterTextChange,
  onSearchTypeChange,
  onFilterEventsClick,
  onDeleteEventClick,
  onLogsUpdate,
  onScrollTopEventClick,
  onWritebackDataChange,
}) => {
  const disabled: boolean = filteredEvents.length === 0;
  const displayEvents: any[] = filterText ? filteredEvents : events;
  return (
    <>
      <div className="events">
        <ButtonIcon
          id="clear-all-event"
          className="button mini"
          onClick={onClearAllEvents}
          disabled={disabled}
          icon={ICONS_MAP['delete']}
        />
        <TextField
          className="search-text"
          placeholder="Search event"
          value={filterText}
          onChange={onFilterTextChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Clear className="clear-filter-icon" onClick={onClearFilterText} />
              </InputAdornment>
            ),
          }}
        />
        <FormControl size="small">
          <Selector
            id="event-search-type"
            labelId="event-search-type-label"
            value={searchType}
            onChange={onSearchTypeChange}
          >
            <MenuItem value={SEARCH_TYPE.NAME.key}>{SEARCH_TYPE.NAME.key}</MenuItem>
            <MenuItem value={SEARCH_TYPE.PAYLOAD.key}>{SEARCH_TYPE.PAYLOAD.key}</MenuItem>
          </Selector>
        </FormControl>
      </div>
      <div className="text-divider">Events ({addLeadingZero(displayEvents.length)})</div>
      {displayEvents.map((event: any) => (
        <Event
          key={getItemId(event.id, event.receivedDate)}
          ref={refs.get(event.id)}
          event={event}
          writebacksData={writebacksData}
          localLogs={localLogs}
          onFilterEventsClick={onFilterEventsClick}
          onDeleteEventClick={onDeleteEventClick}
          onLogsUpdate={onLogsUpdate}
          onScrollTopEventClick={onScrollTopEventClick}
          onWritebackDataChange={onWritebackDataChange}
        />
      ))}
    </>
  );
};

Events.displayName = 'Events';
