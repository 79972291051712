import { Standard } from '@getvim/vim-connect';

export const orrxPete: Standard.Events.PatientInContextPayload = {
  patientId: '667788',
  demographics: {
    firstName: 'Orrx',
    lastName: 'Pete',
    dateOfBirth: '1971-03-17',
  },
  insurance: {
    memberId: 'TSF123',
  },
};
