import { Standard } from '@getvim/vim-connect';
import { AdapterName } from '@getvim/vim-connect-app';
import { defaultActionPayloads } from './default';

const actionsPayloadMaps: Record<
  string,
  Partial<Record<Standard.Actions.ActionNames, Standard.Actions.ActionsStandard>>
> = {
  [AdapterName.DEFAULT]: defaultActionPayloads,
};

export function getActionPredefinedPayload(actionName: Standard.Actions.ActionNames): any {
  const predefinedPayload: any = actionsPayloadMaps[AdapterName.DEFAULT][actionName];
  return predefinedPayload;
}
