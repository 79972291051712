import { Insurer } from '@getvim-os/standard';
import { Standard } from '@getvim/vim-connect';

const demographics: Standard.Entities.PatientDemographics = {
  firstName: 'Thomas',
  lastName: 'Shelby',
  dateOfBirth: '1980-06-24',
  gender: Standard.Entities.Gender.male,
  address: {
    fullAddress: '142 CANA ROAD, Mocksville, NC, 27028',
    address1: '142 CANA ROAD',
    city: 'Mocksville',
    state: 'NC',
    zipCode: '27028',
  },
};

const insurance: Standard.Entities.PatientInsurance = {
  memberId: '863851726',
  ehrInsurance: 'Test',
  insurer: Insurer.UHC,
};

const contact_info: Standard.Entities.PatientContactInfo = {
  homePhoneNumber: '122-122-1222',
};

const pcp: Standard.Entities.Provider = {
  providerEhrId: '40598',
  npi: '1285171983',
  demographics: {
    firstName: 'SOLANGE',
    lastName: 'BAVUSO',
  },
  specialty: [
    {
      description: 'Nurse Practitioner',
    },
  ],
};

const problemList: Standard.Entities.Diagnosis[] = [
  {
    description: 'A-fib',
    code: 'I48.91',
  },
  { description: 'AA (alcohol abuse)', code: 'F10.10' },
];

const patientId: string = '138073';

export const thomasShelby: Standard.Events.PatientInContextPayload = {
  patientId,
  demographics,
  insurance,
  contact_info,
  pcp,
  problemList,
};
