import { RuntimeContract, Standard } from '@getvim/vim-connect';

export const predefinedPayloads: Record<string, RuntimeContract.Incoming.SelectAppPayload> = {
  'Eligibility - automatic': {
    id: 'eligibility',
    expandingType: Standard.Entities.UIElements.ExpandingType.AUTOMATIC,
  },
  'Eligibility - manual': {
    id: 'eligibility',
    expandingType: Standard.Entities.UIElements.ExpandingType.VIM_HUB_MANUAL,
  },
  'Eligibility - vim app manual': {
    id: 'eligibility',
    expandingType: Standard.Entities.UIElements.ExpandingType.VIM_APP_MANUAL,
  },
  'Eligibility - vim-notification-manual': {
    id: 'eligibility',
    expandingType: Standard.Entities.UIElements.ExpandingType.VIM_NOTIFICATION_MANUAL,
  },
};
