import { Standard } from '@getvim/vim-connect';
import { minnieMouse, natashaDavies, stevenVazquez } from '../patients';
import { referralA, referralB, referralC } from '../referrals';

export const predefinedPayloads: Record<string, Standard.Events.ReferralSavedPayload> = {
  'Minnie Mouse - Raferral A': {
    patient: minnieMouse as Standard.Events.TransformedPatientInContextPayload,
    referral: referralA,
  },
  'Steven Vazquez - Raferral B': {
    patient: stevenVazquez as Standard.Events.TransformedPatientInContextPayload,
    referral: referralB,
  },
  'Natasha Davies - Raferral C': {
    patient: natashaDavies as Standard.Events.TransformedPatientInContextPayload,
    referral: referralC,
  },
};
