import { ICONS_MAP } from '../../types';

const resultMap: Record<string, string> = {
  reports: 'reports',
  appointment: 'encounterStart',
  password: 'password',
  login: 'onLoggedIn',
  order: 'orderViewed',
  patient: 'patientInContext',
  progressnotes: 'patientChartOpened',
  referral: 'referralViewed',
};

export function getIcon(input: string): JSX.Element {
  const lowerInput: string = input.toLowerCase();
  for (const key in resultMap) {
    if (lowerInput.includes(key) || key.includes(lowerInput)) {
      return ICONS_MAP[resultMap[key]];
    }
  }
  return ICONS_MAP['order utility'];
}
