import { Standard } from '@getvim/vim-connect';
import { judithFleming, patriciaBell, tonyStark } from '../patients';
import { orderA, orderB, orderC, orderD, orderE, orderF } from '../orders';

export const predefinedPayloads: Record<string, Standard.Events.OrderViewedPayload> = {
  'Tony Stark - Order A': { patient: tonyStark, order: orderA },
  'Patricia Bell - Order B': { patient: patriciaBell, order: orderB },
  'Judith Fleming - Order C': { patient: judithFleming, order: orderC },
  'Tony Stark - Order D': { patient: tonyStark, order: orderD },
  'Tony Stark - Order E': { patient: tonyStark, order: orderE },
  'Tony Stark - Order F': { patient: tonyStark, order: orderF },
};
