import { v4 as uuid } from 'uuid';
import { mickAbae } from '../providers';
import { natashaDavies } from '../patients';

export const encounterIdC = uuid();

export const encounterC = {
  patient: natashaDavies,
  encounterId: encounterIdC,
  provider: mickAbae,
  assessments: [{ description: 'assessments description', code: 'assessments code' }],
};
