import { Standard } from '@getvim/vim-connect';

type NavigateToEncounterAction =
  Standard.Actions.ActionsStandard[Standard.Actions.ActionNames.NAVIGATE_TO_ENCOUNTER];

export const navigateToEncounter: {
  input: NavigateToEncounterAction['input'];
} = {
  input: {
    patientId: '',
    encounterId: '',
  },
};
