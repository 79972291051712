import React, { useEffect } from 'react';
import { Container } from '@mui/material';
import { StandartEventsMain } from '../standart-events-accordion';
import { VimConnectUIMain } from '../vim-connect-ui-accordion';
import { LoginMain } from '../login-accordion';
import { SettingsMain } from '../settings/SettingsMain';
import { Sections, useSettingsContext } from '../../context';
import { SimulatorBridge } from '../simulator-bridge';

export const Main = () => {
  const { selectedSections } = useSettingsContext();
  useEffect(() => {
    document.body.classList.add('main-simulator');
  }, []);
  return (
    <Container maxWidth="md">
      <SettingsMain />

      {selectedSections.includes(Sections.STANDART_EVENTS) && <StandartEventsMain />}
      {selectedSections.includes(Sections.DEMO_APP_SIMULATOR_BRIDGE) && <SimulatorBridge />}
      {selectedSections.includes(Sections.VIM_CONNECT_UI_EVENTS) && <VimConnectUIMain />}
      {selectedSections.includes(Sections.LOGIN_EVENTS) && <LoginMain />}
    </Container>
  );
};
