import { ConnectorsType, connectors, ExecuteConnectorFunction } from '../../../types';

const funcRegex: RegExp = /function\s\w+\(([^)]*)\)/;
const argsSplitRegex: RegExp = /\s*,\s*/;
const argsCleanRegex: RegExp = /\s+/g;
const argsMatchRegex: RegExp = /\b\w+\b/g;

function createPayload(argumentList: string[]): object {
  const payload: object = {};
  for (const argument of argumentList) {
    payload[argument] = '';
  }
  return payload;
}

function getConnectorsFunctions(type: string): object {
  const connectorFunctions: object = {};
  const connector: any = connectors[type] || {};
  Object.keys(connector).forEach((key) => {
    const value: any = connector[key];
    if (value && typeof value === 'function') {
      let argumentList: string[] | null = [];
      const match: RegExpMatchArray = connector[key].toString().match(funcRegex);
      if (match) {
        const argumentsString: string = match[1].trim();
        const argumentsArray: string[] = argumentsString.split(argsSplitRegex);
        const cleanedString: string = argumentsArray.join(' ').replace(argsCleanRegex, ' ');
        const results: RegExpMatchArray | null = cleanedString.match(argsMatchRegex);
        if (results && results.length > 0) {
          argumentList = Array.from(results);
        }
      }
      connectorFunctions[key] = argumentList ? createPayload(argumentList) : [];
    }
  });
  return connectorFunctions;
}

export async function executeConnectorFunction({
  type,
  funcName,
  payload,
}: ExecuteConnectorFunction): Promise<any> {
  let result: any = null;
  try {
    // Print the function name and arguments.
    console.log(`Executing: ${type} | ${funcName}`);
    console.log(payload);
    result = await connectors[type][funcName](payload);
  } catch (error: any) {
    result = JSON.stringify(error);
  }
  return result ?? 'undefined';
}

export const connectorsMocks: Record<string, object> = {
  [ConnectorsType.COMMON]: getConnectorsFunctions(ConnectorsType.COMMON),
  [ConnectorsType.DESKTOP]: getConnectorsFunctions(ConnectorsType.DESKTOP),
  [ConnectorsType.WEB]: getConnectorsFunctions(ConnectorsType.WEB),
};
