import { OSSettings } from '@getvim/vim-connect-app';

function isNullOrUndefined(value: any): value is null | undefined {
  return value === null || value === undefined;
}

export const isSqlSecretsExists = (osSettings?: OSSettings): boolean => {
  return Object.values(osSettings?.sqlSecrets?.sqlSecrets ?? {}).every(
    (value) => !isNullOrUndefined(value) && value !== '',
  );
};

export function isDarkMode(): boolean {
  return document.querySelector('.os-simulator')?.classList.contains('dark') ? true : false;
}
