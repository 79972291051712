import { Standard } from '@getvim/vim-connect';

type GetCurrentEhrUserInformationAction =
  Standard.Actions.ActionsStandard[Standard.Actions.ActionNames.GET_CURRENT_EHR_USER_INFORMATION];

export const getCurrentEhrUserInformation: {
  input: GetCurrentEhrUserInformationAction['input'];
} = {
  input: undefined,
};
