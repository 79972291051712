import { omit } from 'lodash-es';

export function removeToString(data: any): any {
  if (Array.isArray(data)) {
    return data.map((item) => removeToString(item));
  }
  if (typeof data === 'object' && data !== null) {
    const cleanedData: any = omit(data, ['to-string']);
    for (const key in cleanedData) {
      cleanedData[key] = removeToString(cleanedData[key]);
    }
    return cleanedData;
  }
  return data;
}
