import { Box } from '@mui/material';
import React from 'react';
import { styled } from '@mui/system';
import { Header } from '../layout/Header';
import { CheckboxGroup } from './CheckboxGroup';
import { ButtonGroup } from './ButtonGroup';

export const SettingsMain = () => (
  <StyledSettingsMainContainer>
    <Header title="Settings" />

    <StyledRow>
      <ButtonGroup />
      <CheckboxGroup />
    </StyledRow>
  </StyledSettingsMainContainer>
);

const StyledSettingsMainContainer = styled(Box)`
  background: #fff;
`;

const StyledRow = styled(Box)`
  padding: 0 1rem;
`;
