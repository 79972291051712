import { UpdateEncounterType } from '../../../../types';
import { getUpdateProcedurePayload } from './updateProcedures';
import { getWritebackAssessmentsPayload } from './writebackAssessment';
import {
  getUpdateSOAPPayload,
  getUpdateSOAPAssessmentGNPayload,
  getUpdateSOAPObjectivePayload,
  getUpdateSOAPPatientInstructionsGNPayload,
  getUpdateSOAPPlanGNPayload,
  getUpdateSOAPSubjectivePayload,
} from './updateSOAP';
export * from './writebackAssessment';
export * from './useCases';

const functions: any = {
  [UpdateEncounterType.WRITEBACK_SOAP]: getUpdateSOAPPayload,
  [UpdateEncounterType.WRITEBACK_ASSESSMENT]: getWritebackAssessmentsPayload,
  [UpdateEncounterType.UPDATE_PROCEDURES]: getUpdateProcedurePayload,
  [UpdateEncounterType.UPDATE_OBJECTIVE]: getUpdateSOAPObjectivePayload,
  [UpdateEncounterType.UPDATE_SUBJECTIVE]: getUpdateSOAPSubjectivePayload,
  [UpdateEncounterType.UPDATE_ASSESSMENT_GN]: getUpdateSOAPAssessmentGNPayload,
  [UpdateEncounterType.UPDATE_PLAN_GN]: getUpdateSOAPPlanGNPayload,
  [UpdateEncounterType.UPDATE_PATIENT_INSTRUCTIONS_GN]: getUpdateSOAPPatientInstructionsGNPayload,
};

export function getUpdateEncounterPayload(selectType: string, key: string): string {
  return functions[selectType](key);
}
