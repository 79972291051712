import React, { FC } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { TabPanelProps } from '../../../types';

export const TabPanel: FC<TabPanelProps> = ({ name, children, value, index, ...other }) => {
  return (
    <Typography
      id={`tabpanel-${index}`}
      className="tab-panel"
      data-name={name}
      role="tabpanel"
      component="div"
      hidden={value !== index}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Grid>{children}</Grid>
        </Box>
      )}
    </Typography>
  );
};

TabPanel.displayName = 'TabPanel';
