import { sendDataToApp } from '@getvim/utils-vim-connect-communication';
import {
  DisplayMode,
  TriggerRequest,
  OSScreenPosition,
  OSLog,
  AutomationPayload,
  OptumProvidersType,
} from '@getvim/vim-connect-app';

export function sendTabIdData(tabId: number): void {
  sendDataToApp({
    type: TriggerRequest.TRIGGER_OS_SIMULATOR_DATA,
    data: {
      objectName: 'osGeneral',
      propertyName: 'tabId',
      data: tabId,
    },
  });
}

export function sendWidgetExpandedData(widgetExpanded: boolean): void {
  sendDataToApp({
    type: TriggerRequest.TRIGGER_OS_SIMULATOR_DATA,
    data: {
      objectName: 'osGeneral',
      propertyName: 'widgetExpanded',
      data: widgetExpanded,
    },
  });
}

export function sendScreenPositionData(screenPosition: OSScreenPosition | null): void {
  sendDataToApp({
    type: TriggerRequest.TRIGGER_OS_SIMULATOR_DATA,
    data: {
      objectName: 'osGeneral',
      propertyName: 'screenPosition',
      data: screenPosition,
    },
  });
}

export function sendDisplayModeData(displayMode: DisplayMode): void {
  sendDataToApp({
    type: TriggerRequest.TRIGGER_OS_SIMULATOR_DATA,
    data: {
      objectName: 'osGeneral',
      propertyName: 'displayMode',
      data: displayMode,
    },
  });
}

export function sendStartupAuthMonitorsData(startupAuthMonitors: boolean): void {
  sendDataToApp({
    type: TriggerRequest.TRIGGER_OS_SIMULATOR_DATA,
    data: {
      objectName: 'osGeneral',
      propertyName: 'startupAuthMonitors',
      data: startupAuthMonitors,
    },
  });
}

export function sendSetStateConfidentFilterData(isStateConfidentFilter: boolean): void {
  sendDataToApp({
    type: TriggerRequest.TRIGGER_OS_SIMULATOR_DATA,
    data: {
      objectName: 'osGeneral',
      propertyName: 'isStateConfidentFilter',
      data: isStateConfidentFilter,
    },
  });
}

export function sendAutomationData(data: AutomationPayload): void {
  sendDataToApp({
    type: TriggerRequest.TRIGGER_AUTOMATION,
    data,
  });
}

export function sendAutomationV2Data(data: AutomationPayload): void {
  sendDataToApp({
    type: TriggerRequest.TRIGGER_AUTOMATION_V2,
    data,
  });
}

export function sendActionData(data: any): void {
  sendDataToApp({
    type: TriggerRequest.TRIGGER_ACTION,
    data,
  });
}

export function sendWritebackData(data: any): void {
  sendDataToApp({
    type: TriggerRequest.TRIGGER_WRITEBACK,
    data,
  });
}

export function sendWritebackV2Data(data: any): void {
  sendDataToApp({
    type: TriggerRequest.TRIGGER_WRITEBACK_V2,
    data,
  });
}

export function sendUIInjectionData(data: any): void {
  sendDataToApp({
    type: TriggerRequest.TRIGGER_UI_INJECTION,
    data,
  });
}

export function sendOrganizationConfigData(setBoth: boolean, data: any): void {
  sendDataToApp({
    type: TriggerRequest.TRIGGER_OS_SIMULATOR_DATA,
    data: {
      objectName: 'osSettings',
      propertyName: 'sqlSecrets',
      data,
      additionalData: { setBoth },
    },
  });
}

export function sendOrganizationConfig() {
  sendDataToApp({
    type: TriggerRequest.TRIGGER_ORGANIZATION_CONFIG,
  });
}

export function sendLogsData(logs: OSLog[]): void {
  sendDataToApp({
    type: TriggerRequest.TRIGGER_OS_SIMULATOR_DATA,
    data: {
      objectName: 'osLogs',
      propertyName: 'logs',
      data: logs,
    },
  });
}

export function sendRunAuthenticatedMonitorsData(): void {
  sendDataToApp({
    type: TriggerRequest.RUN_AUTHENTICATED_MONITORS,
  });
}

export function sendCapabilitiesConfigData(): void {
  sendDataToApp({
    type: TriggerRequest.TRIGGER_CAPABILITIES_CONFIG,
  });
}

export function sendAdapterKPIData(): void {
  sendDataToApp({
    type: TriggerRequest.TRIGGER_ADAPTER_KPI,
  });
}

export function sendClearOSSimulatorData(): void {
  sendDataToApp({
    type: TriggerRequest.CLEAR_OS_SIMULATOR_DATA,
  });
}

export function sendExtractEcwDataForOptum(providerType: OptumProvidersType): void {
  sendDataToApp({
    type: TriggerRequest.EXTRACT_ECW_DATA_FOR_OPTUM,
    data: providerType,
  });
}

export function sendReportNamesData(): void {
  sendDataToApp({
    type: TriggerRequest.TRIGGER_REPORTS_NAMES,
  });
}

export function sendReportData(name: string, download: boolean): void {
  sendDataToApp({
    type: TriggerRequest.TRIGGER_REPORT,
    data: { name, download },
  });
}
