import { Standard } from '@getvim/vim-connect';
import { orderA, orderB, orderC } from '../orders';
import { minnieMouse, natashaDavies, stevenVazquez } from '../patients';

export const predefinedPayloads: Record<string, Standard.Events.OrdersViewedPayload> = {
  'Minnie Mouse - Orders A-B': {
    patient: minnieMouse,
    selectedOrders: [orderA, orderB],
  },
  'Steven Vazquez - Order B-C': {
    patient: stevenVazquez,
    selectedOrders: [orderB, orderC],
  },
  'Natasha Davies - Order A-C': {
    patient: natashaDavies,
    selectedOrders: [orderA, orderC],
  },
};
