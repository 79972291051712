export const predefinedPayloads: Record<string, any> = {
  Eligibility: {
    id: 'eligibility',
    modifications: {
      disabled: false,
      loading: true,
      notifications: 5,
      ignoreNotifications: false,
      tooltip: 'Eligibility',
      responseTime: 1000,
      reason: 'response',
    },
  },
};
