import React, { FC, MouseEvent, useRef, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { JsonViewer } from '../common';
import { getDisplayTime } from '../../../services';
import { HiddenJsonType, StateProps } from '../../../types';

export const State: FC<StateProps> = ({ state }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const targetRef = useRef<HTMLElement | null>(null);
  const { id, receivedDate, data } = state;

  const handleExpandState = (e: MouseEvent<HTMLDivElement>): void => {
    const targetElement: HTMLElement = e.currentTarget as HTMLElement;
    if (!targetElement.classList.contains('adapter-state-title')) {
      return;
    }
    if (!expanded) {
      setTimeout(() => {
        targetRef.current?.scrollIntoView();
      }, 200);
    }
    setExpanded(!expanded);
  };

  const { displayTime } = getDisplayTime(receivedDate);
  const resources: string[] = Object.keys(data.resources);

  return (
    <Accordion className="state-wrap" data-id={id} expanded={expanded}>
      <AccordionSummary onClick={handleExpandState} className="adapter-state-title">
        <div className="state">
          <div className={`state-box state-confidence c-${data.confidence}`}>{data.confidence}</div>
          <div className="state-box state-body">
            {resources.map((resource: string) => (
              <div key={resource}>{resource}</div>
            ))}
          </div>
          <div className="state-box state-display-time">{displayTime}</div>
        </div>
      </AccordionSummary>
      <AccordionDetails ref={targetRef}>
        <JsonViewer id={id} type={HiddenJsonType.STATE} value={data} collapsed={2} />
      </AccordionDetails>
    </Accordion>
  );
};

State.displayName = 'State';
