import React, { FC, useRef, useState } from 'react';
import ReactJson from 'react-json-view';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { omit } from 'lodash-es';
import { OSWritebackLog } from '@getvim/vim-connect-app';
import { getDisplayTime, getLogStatus } from '../../../services';
import { LogProps, SHORT_TO_LOG, ICONS_MAP } from '../../../types';

export const Log: FC<LogProps> = ({ log }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const targetRef = useRef<HTMLElement | null>(null);

  const handleExpandLog = (): void => {
    if (!expanded) {
      setTimeout(() => {
        targetRef.current?.scrollIntoView();
      }, 200);
    }
    setExpanded(!expanded);
  };

  const { id, createTime, type, status } = log;
  const logType: string = SHORT_TO_LOG[type] || type;
  const logStatus: string = getLogStatus(status);
  const { displayTime } = getDisplayTime(new Date(createTime), true);

  return (
    <Accordion className="log-wrap" data-id={id} expanded={expanded}>
      <AccordionSummary
        className="adapter-log-title"
        expandIcon={ICONS_MAP['expand-more']}
        onClick={handleExpandLog}
      >
        <div className="log">
          <div className="log-box log-icon">{ICONS_MAP[logType]}</div>
          <div className="log-box log-title">{`${SHORT_TO_LOG[type] || type}${
            (log as OSWritebackLog).isAutoTest !== undefined
              ? `${(log as OSWritebackLog).isAutoTest ? ' - Summary' : ''}`
              : ''
          }`}</div>
          <div className={`log-box log-status ${logStatus}`}>{ICONS_MAP[logStatus]}</div>
          <div className="log-box log-display-time">{displayTime}</div>
        </div>
      </AccordionSummary>
      <AccordionDetails ref={targetRef}>
        <ReactJson
          collapsed={1}
          src={omit(log, ['createTime', 'operationType', 'type', 'to-string', 'isAutoTest'])}
        />
      </AccordionDetails>
    </Accordion>
  );
};

Log.displayName = 'Log';
