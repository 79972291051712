import { Standard } from '@getvim/vim-connect';

export const ronrxOrdy: Standard.Events.PatientInContextPayload = {
  patientId: '555777',
  demographics: {
    firstName: 'Ronrx',
    lastName: 'Ordy',
    dateOfBirth: '1984-03-25',
  },
  insurance: {
    memberId: 'BFF555',
  },
};
