import React, { useState } from 'react';
import { Accordion, AccordionSummary, Typography, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { WidgetIncomingEvent } from '@getvim/vim-connect-app';
import { handlePublish } from './utils';

export const RecoverErrorTab = () => {
  const [payload] = useState<string>();

  return (
    <Accordion id="recoverErrorTab">
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>RecoverError</Typography>
        <Button
          id="publish-recover-error"
          fullWidth
          onClick={(e) => {
            e.stopPropagation();
            handlePublish(payload, WidgetIncomingEvent.RecoverError);
          }}
          variant="contained"
        >
          Publish
        </Button>
      </AccordionSummary>
    </Accordion>
  );
};
