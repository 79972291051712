import { Standard } from '@getvim/vim-connect';

export function getReferralAll(): Standard.WriteBacks.UpdateReferral {
  return {
    specialty: {
      description: 'Family Medicine',
      taxonomies: [
        '207K00000X',
        '207KA0200X',
        '207KI0005X',
        '207RA0201X',
        'BKMD000003',
        'BKMD000252',
      ],
    },
    diagnosis: [
      {
        code: 'J30.89',
        description: 'Other allergic rhinitis',
      },
    ],
    reasons: ['reason a', 'reason b'],
    startDate: '2020-01-01',
    endDate: '2040-01-02',
    notes: `Vim Select Provider Result:
CORRINGTON, KIP ALAM, NPI: 1215049242
Specialties: Family Medicine
Address: 6915 Village Medical Cir, Clemmons, NC 27012
Phone: (012) 345-6789
Fax: ---`,
    priority: Standard.Entities.ReferralPriority.ROUTINE,
    cpts: [
      {
        name: 'Office visit',
        code: '99215',
      },
    ],
    numberOfVisits: '10',
    targetProvider: {
      npi: '1487176566',
      specialty: [
        {
          description: 'Family Medicine',
        },
      ],
      demographics: {
        firstName: 'YUNIOR A',
        lastName: 'FALLS ALVAREZ',
      },
      organizations: [
        {
          name: 'Sanitas Tampa',
          address: {
            address1: '3617 W HILLSBOROUGH AVE',
            address2: '',
            city: 'TAMPA',
            state: 'FL',
            zipCode: '33614-5700',
            fullAddress: '3617 W HILLSBOROUGH AVE, TAMPA, FL, 33614-5700',
          },
          contact_info: {
            mobilePhoneNumber: '844-665-4827',
          },
        },
      ],
    },
    authCode: '220808117875',
  };
}
