import { Standard } from '@getvim/vim-connect';

type GetEncounterLabResultsAction =
  Standard.Actions.ActionsStandard[Standard.Actions.ActionNames.GET_ENCOUNTER_LAB_RESULTS];

export const getEncounterLabResults: {
  input: GetEncounterLabResultsAction['input'];
} = {
  input: {
    encounterId: '',
    patientId: '',
  },
};
