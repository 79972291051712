import React, { useState, FC, MouseEvent } from 'react';
import { AdapterName } from '@getvim/vim-connect-app';
import { ButtonLoader } from '../common';
import { isSqlSecretsExists } from '../../../services';
import { ICONS_MAP, HeaderWarningProps, WarningType, WarningData, TabType } from '../../../types';

export const HeaderWarning: FC<HeaderWarningProps> = ({
  isAuthMonitorRunning,
  adapterName,
  settings,
  onNavigateToTab,
  onRunMonitorsClick,
}) => {
  const [warningData, setWarningData] = useState<WarningData>({
    type: WarningType.WARNING,
    icon: 'warning',
    text: 'Authenticate monitors are not running',
  });

  const handleRunMonitorsClick = (e: MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    const isNextGen: boolean = adapterName === AdapterName.NEXTGEN_DESKTOP;
    if (isNextGen && !isSqlSecretsExists(settings)) {
      onNavigateToTab(TabType.SETTINGS);
    } else {
      setWarningData({
        type: WarningType.SUCCESS,
        icon: 'success',
        text: 'Authenticate monitors are now running successfully',
      });
      onRunMonitorsClick({ value: true, setIsAuth: false, sendData: true, setBoth: isNextGen });
      setTimeout(() => {
        onRunMonitorsClick({ value: true, setIsAuth: true, sendData: false, setBoth: false });
      }, 10_000);
    }
  };

  if (isAuthMonitorRunning) {
    return null;
  }

  const { type, icon, text } = warningData;

  return (
    <div className="header-warning-container">
      <div className={`header-warning-body ${type}`}>
        {type === WarningType.WARNING && (
          <>
            {ICONS_MAP[icon]} {text} {ICONS_MAP[icon]}
          </>
        )}
        {type === WarningType.SUCCESS && (
          <>
            {ICONS_MAP[icon]} {text}
          </>
        )}
      </div>
      {type === WarningType.WARNING && (
        <ButtonLoader
          id="main-run-monitors"
          className="button run-monitors"
          disableLoader
          onClick={handleRunMonitorsClick}
        />
      )}
    </div>
  );
};

HeaderWarning.displayName = 'HeaderWarning';
