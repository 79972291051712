import React, { FC } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { SettingsProvider } from './context';
import { Main } from './components/main/Main';
import { OsSimulator } from './os-simulator';
import { InlineSimulatorWidget } from './os-simulator/components/inline-simulator-widget/InlineSimulatorWidget';
import './App.less';

const router = createBrowserRouter([
  {
    path: '/os-simulator',
    element: <OsSimulator />,
  },
  {
    path: '/inline-simulator-widget',
    element: <InlineSimulatorWidget />,
  },
  {
    path: '*',
    element: <Main />,
  },
]);

export const App: FC = () => (
  <SettingsProvider>
    <RouterProvider router={router} />
  </SettingsProvider>
);
