import React, { FC, useState, useEffect } from 'react';
import { TextField, InputAdornment, MenuItem, FormControl } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { Clear } from '@mui/icons-material';
import { OSLog, AutomationOperationType } from '@getvim/vim-connect-app';
import { Log } from '../log';
import { ButtonIcon, Selector } from '../common';
import { addLeadingZero } from '../../../services';
import { LogsProps, OPERATION_TYPE_LIST, ICONS_MAP } from '../../../types';

export const Logs: FC<LogsProps> = ({ logs, onClearAllLogs }) => {
  const [localLogs, setLocalLogs] = useState<OSLog[]>([]);
  const [filterType, setFilterType] = useState<AutomationOperationType>(
    OPERATION_TYPE_LIST[0] as AutomationOperationType,
  );

  const handleFilterLogChange = (e: SelectChangeEvent): void => {
    setFilterType(e.target.value as AutomationOperationType);
  };

  useEffect((): void => {
    setLocalLogs(logs?.logs ?? []);
  }, [logs]);

  return (
    <div className="logs">
      <div className="panel">
        <div className="controls-container">
          <div className="clear-logs">
            <ButtonIcon
              id="clear-all-logs"
              className="button mini"
              onClick={onClearAllLogs}
              disabled={false}
              icon={ICONS_MAP['delete']}
            />
          </div>
          <div className="sort-logs">
            <ButtonIcon
              id="clear-all-logs"
              className="button mini"
              onClick={onClearAllLogs}
              disabled={false}
              icon={ICONS_MAP['sort-up']}
            />
          </div>
          <div className="search-logs">
            <TextField
              className="search-text"
              placeholder="Search log"
              value={''}
              onChange={() => {}}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Clear className="clear-filter-icon" onClick={() => {}} />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="filter-logs">
            <FormControl size="small">
              <Selector
                id="log-search-type"
                labelId="log-search-type-label"
                value={filterType}
                onChange={handleFilterLogChange}
              >
                {OPERATION_TYPE_LIST.map((key: string) => (
                  <MenuItem key={key} value={key}>
                    {key}
                  </MenuItem>
                ))}
              </Selector>
            </FormControl>
          </div>
        </div>
      </div>
      <div className="text-divider">Logs ({addLeadingZero(localLogs.length)})</div>
      {(localLogs || [])
        .sort((a: OSLog, b: OSLog) => b.createTime - a.createTime)
        .map((log: OSLog) => (
          <Log key={log.id} log={log} />
        ))}
    </div>
  );
};

Logs.displayName = 'Logs';
