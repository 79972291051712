import React from 'react';
import { Button, ButtonGroup as MuiButtonGroup } from '@mui/material';
import { styled } from '@mui/system';

export const ButtonGroup = () => (
  <StyledMuiButtonGroup variant="contained" color="success">
    <Button id="home-button" href="/">/</Button>
    <Button id="login-button" href="/login">/login</Button>
  </StyledMuiButtonGroup>
);

const StyledMuiButtonGroup = styled(MuiButtonGroup)`
  margin: 1rem 0;
`;
