import { Insurer } from '@getvim-os/standard';
import { Standard } from '@getvim/vim-connect';

const demographics: Standard.Entities.PatientDemographics = {
  firstName: 'Gustavo',
  lastName: 'Fring',
  dateOfBirth: '1968-11-11',
  gender: Standard.Entities.Gender.male,
  address: {
    fullAddress: 'Los Pollos Hermanos, Albuquerque, NM',
    address1: 'Los Pollos Hermanos',
    city: 'Albuquerque',
    state: 'NM',
  },
};

const insurance: Standard.Entities.PatientInsurance = {
  memberId: '123451234',
  ehrInsurance: 'Test',
  insurer: Insurer.UHC,
};

const contact_info: Standard.Entities.PatientContactInfo = {
  homePhoneNumber: '122-122-1222',
};

const pcp: Standard.Entities.Provider = {
  providerEhrId: '40598',
  npi: '1285171983',
  demographics: {
    firstName: 'SOLANGE',
    lastName: 'BAVUSO',
  },
  specialty: [
    {
      description: 'Nurse Practitioner',
    },
  ],
};

const problemList: Standard.Entities.Diagnosis[] = [
  {
    description: 'A-fib',
    code: 'I48.91',
  },
  { description: 'AA (alcohol abuse)', code: 'F10.10' },
];

const patientId: string = '138074';

export const gustavoFring: Standard.Events.PatientInContextPayload = {
  patientId,
  demographics,
  insurance,
  contact_info,
  pcp,
  problemList,
};
