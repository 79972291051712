import React, { useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useLocalStorage from 'react-localstorage-hook';

export const EncounterExitTab = () => {
  const [, setShouldPublish] = useLocalStorage('shouldPublishEncounterExit', false);

  useEffect(() => {
    setShouldPublish(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePublish = () => setShouldPublish(true);

  return (
    <Accordion id="encounterExitTab">
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>encounterExit</Typography>
        <Button
          id="publish-encounter-exit"
          fullWidth
          onClick={(e) => {
            e.stopPropagation();
            handlePublish();
          }}
          variant="contained"
        >
          Publish
        </Button>
      </AccordionSummary>
      <AccordionDetails />
    </Accordion>
  );
};
