import { Standard } from '@getvim/vim-connect';
import { v4 as uuid } from 'uuid';
import { mickAbae } from '../providers';

export const referralIdD = uuid();

export const referralD: Standard.Entities.Referral = {
  referralId: referralIdD,
  specialty: {
    description: 'specialty description',
    taxonomies: [uuid(), uuid()],
  },
  diagnosis: [{ description: 'diagnosis description', code: uuid() }],
  targetProvider: mickAbae,
  referringProvider: mickAbae,
  reasons: ['reason a', 'reason b'],
  startDate: '2020-01-01',
  endDate: '2020-01-02',
  status: Standard.Entities.ReferralStatus.DEFAULT,
  priority: Standard.Entities.ReferralPriority.ROUTINE,
  notes: 'notes',
  numberOfVisits: '10',
  cpts: [
    {
      name: 'cpts name',
      code: 'cpts code',
    },
  ],
};
