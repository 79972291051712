import { Standard } from '@getvim/vim-connect';
import { v4 as uuid } from 'uuid';
import { encounterIdA } from '../encounters';

export const orderF: Standard.Entities.Order = {
  id: uuid(),
  type: Standard.Entities.OrderType.LAB,
  icd: [],
  cpts: [
    {
      name: 'Tenodesis long tendon biceps',
      code: '23430',
    },
  ],
  encounterId: encounterIdA,
  createdDate: '2000-01-01',
};
