import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useLocalStorage from 'react-localstorage-hook';
import { Standard } from '@getvim/vim-connect';
import { isEmpty } from 'lodash-es';
import { OnLoggedIn } from '../../mocks/standard-events';
import { FlexBox } from '../common/FlexBox';
import { MonacoEditor } from '../monaco-editor';

interface MonacoEditorPayload {
  authToken: string;
  password: string;
  username: string;
}

export const OnLoggedInTab = () => {
  const [payload, setPayload] = useState<Standard.Events.OnLoggedInPayload>({});
  const [predefinedPayload, setPredefinedPayload] = useState<string>('');
  const [lastUsedPayload, setLastUsedPayload] = useLocalStorage('lastOnLoggedInPayload', '');
  const [authToken, setAuthToken] = useLocalStorage('authToken', '');
  const [, setShouldPublish] = useLocalStorage('shouldPublishOnLoggedIn', false);

  useEffect(() => {
    handlePredefinedPayloadChange({ target: { value: lastUsedPayload } } as SelectChangeEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { pathname } = window.location;

    if (pathname.includes('login')) {
      handlePredefinedPayloadChange({
        target: { value: lastUsedPayload || 'simulator@getvim.com' },
      } as SelectChangeEvent);

      setShouldPublish(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMonacoEditorChange = (monacoEditorPayload: MonacoEditorPayload) => {
    const { authToken: currentAuthToken } = monacoEditorPayload;
    setAuthToken(currentAuthToken);

    setPayload(monacoEditorPayload);
  };

  const handlePublish = () => setShouldPublish(true);

  const handlePredefinedPayloadChange = ({ target: { value } }: SelectChangeEvent) => {
    setPredefinedPayload(value);
    setLastUsedPayload(value);

    const calculatedAuthToken = authToken || OnLoggedIn.predefinedPayloads[value]?.authToken;
    setPayload({ ...OnLoggedIn.predefinedPayloads[value], authToken: calculatedAuthToken });
  };

  return (
    <Accordion id="onLoggedInTab">
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>onLoggedIn</Typography>
        <Select
          id="select-on-logged-in-user"
          displayEmpty
          value={predefinedPayload}
          size="small"
          onChange={handlePredefinedPayloadChange}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <MenuItem value="">Choose</MenuItem>
          {Object.keys(OnLoggedIn.predefinedPayloads).map((value) => (
            <MenuItem value={value} key={value}>
              {value}
            </MenuItem>
          ))}
        </Select>

        <Button
          id="publish-on-logged-in"
          disabled={isEmpty(predefinedPayload)}
          fullWidth
          onClick={(e) => {
            e.stopPropagation();
            handlePublish();
          }}
          variant="contained"
        >
          Publish
        </Button>
      </AccordionSummary>
      <AccordionDetails>
        <MonacoEditor onChange={onMonacoEditorChange} height="10rem" value={payload} />

        <FlexBox>
          <TextField
            id="onLoggedInPayload"
            variant="outlined"
            size="small"
            value={JSON.stringify(payload)}
            sx={{ display: 'none' }}
          />
        </FlexBox>
      </AccordionDetails>
    </Accordion>
  );
};
