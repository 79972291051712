import React, { forwardRef, createRef, useState, useImperativeHandle } from 'react';
import { OPERATIONS_MAP } from '../../../types';
import { Operation } from './Operation';

export const Operations = forwardRef<any, any>(
  (
    {
      id,
      eventName,
      eventType,
      writebacksData,
      localLogs,
      writebacks,
      uiInjections,
      onLogsUpdate,
      onWritebackDataChange,
    },
    ref,
  ) => {
    const [refs] = useState((): void => {
      const initialRefs: any = {};
      [...writebacks, ...uiInjections]
        .filter((operation: any) => !!OPERATIONS_MAP[operation])
        .forEach((id: any) => {
          initialRefs[id] = createRef<unknown>();
        });
      return initialRefs;
    });

    useImperativeHandle(ref, () => ({
      operationResponse: (id: any, status: any) => {
        const child: any = refs[id];
        if (child && child.current) {
          child.current.operationResponse(status);
        }
      },
      operationResponseV2: (id: any, status: any) => {
        const child: any = refs[id];
        if (child && child.current) {
          child.current.operationResponseV2(status);
        }
      },
    }));

    const operations: any = {
      writebacks: writebacks,
      uiInjections: uiInjections,
    };

    return (
      <>
        {(operations.writebacks.length > 0 || operations.uiInjections.length > 0) && (
          <Operation
            id={id}
            key={id}
            ref={refs[operations.writebacks[0]]}
            eventName={eventName}
            eventType={eventType}
            writebacksData={writebacksData}
            localLogs={localLogs}
            writebacks={operations.writebacks}
            uiInjections={operations.uiInjections}
            onLogsUpdate={onLogsUpdate}
            onWritebackDataChange={onWritebackDataChange}
          />
        )}
      </>
    );
  },
);

Operations.displayName = 'Operations';
