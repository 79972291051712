import { getReferralAll } from './referralAll';
import { UpdateReferralType } from '../../../../types';

const functions: any = {
  [UpdateReferralType.REFERRAL_ALL]: getReferralAll,
};

export function getUpdateReferralPayload(selectType: string, key: string): string {
  return functions[selectType](key);
}
