import React, { FC } from 'react';
import { Box } from '@mui/material';
import { Header } from '../layout/Header';
import { StandartEventsAccordion } from './StandartEventsAccordion';

export const StandartEventsMain: FC = () => (
  <Box className="accordion-wrapper">
    <Header title="Standard Events" />
    <StandartEventsAccordion />
  </Box>
);
