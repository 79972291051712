import React, { FC } from 'react';
import {
  DisplayErrorTab,
  ModifyApplicationTab,
  RecoverErrorTab,
  VCHubSendToNotificationsTab,
  SelectApplicationTab,
} from '.';

export const VimConnectUIAccordion: FC = () => (
  <>
    <DisplayErrorTab />
    <RecoverErrorTab />
    <VCHubSendToNotificationsTab />
    <ModifyApplicationTab />
    <SelectApplicationTab />
  </>
);
