import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Standard } from '@getvim/vim-connect';
import useLocalStorage from 'react-localstorage-hook';
import { isEmpty } from 'lodash-es';
import { ReferralViewed } from '../../mocks/standard-events';
import { FlexBox } from '../common/FlexBox';
import { MonacoEditor } from '../monaco-editor';

export const ReferralViewedTab = () => {
  const [payload, setPayload] = useState<Standard.Events.ReferralViewedPayload>();
  const [predefinedPayload, setPredefinedPayload] = useState<string>('');
  const [lastUsedPayload, setLastUsedPayload] = useLocalStorage('lastReferralViewedPayload', '');
  const [, setShouldPublish] = useLocalStorage('shouldPublishReferralViewed', false);

  useEffect(() => {
    handlePredefinedPayloadChange({ target: { value: lastUsedPayload } } as SelectChangeEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShouldPublish(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePublish = () => setShouldPublish(true);

  const handlePredefinedPayloadChange = ({ target: { value } }: SelectChangeEvent) => {
    setPredefinedPayload(value);
    setLastUsedPayload(value);
    setPayload(ReferralViewed.predefinedPayloads[value]);
  };

  return (
    <Accordion id="referralViewedTab">
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>referralViewed</Typography>
        <Select
          displayEmpty
          value={predefinedPayload}
          size="small"
          id="referral-viewed-select"
          onChange={handlePredefinedPayloadChange}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <MenuItem value="">Choose</MenuItem>
          {Object.keys(ReferralViewed.predefinedPayloads).map((value) => (
            <MenuItem
              value={value}
              key={value}
              id={`referral-viewed-${value.replaceAll(' ', '-')}`}
            >
              {value}
            </MenuItem>
          ))}
        </Select>

        <Button
          id="publish-referral-viewed"
          disabled={isEmpty(predefinedPayload)}
          fullWidth
          onClick={(e) => {
            e.stopPropagation();
            handlePublish();
          }}
          variant="contained"
        >
          Publish
        </Button>
      </AccordionSummary>
      <AccordionDetails id="referralViewedAccordionDetails">
        <MonacoEditor onChange={setPayload} height="20rem" value={payload} />

        <FlexBox>
          <TextField
            id="referralViewedPayload"
            variant="outlined"
            size="small"
            value={JSON.stringify(payload)}
            sx={{ display: 'none' }}
          />
        </FlexBox>
      </AccordionDetails>
    </Accordion>
  );
};
