export function getMonitorDisplayTime(input: number): string | null {
  if (!input) {
    return null;
  }
  const inputTrim: string = input.toString().trim();
  if (inputTrim === '' || inputTrim === '0') {
    return '0';
  }
  const number: number = Number.parseFloat(inputTrim);
  return !Number.isNaN(number) && Number.isFinite(number) ? number.toFixed(5) : null;
}
