import { v4 as uuid } from 'uuid';
import { mickAbae } from '../providers';
import { patriciaBell } from '../patients';

export const encounterIdD = uuid();

export const encounterD = {
  patient: patriciaBell,
  encounterId: encounterIdD,
  provider: mickAbae,
  assessments: [{ description: 'assessments description', code: 'assessments code' }],
};
