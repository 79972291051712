import { Insurer } from '@getvim-os/standard';
import { Standard } from '@getvim/vim-connect';

const demographics: Standard.Entities.PatientDemographics = {
  dateOfBirth: '1975-08-19',
  firstName: 'Max',
  lastName: 'Dale',
  gender: Standard.Entities.Gender.female,
  address: {
    address1: '142 CANA ROAD',
    city: 'MOCKSVILLE',
    fullAddress: '142 CANA ROAD, MOCKSVILLE, NC, 27028',
    state: 'NC',
    zipCode: '27028',
  },
};

const insurance: Standard.Entities.PatientInsurance = {
  memberId: '863851726',
  ehrInsurance: 'Test',
  insurer: Insurer.UHC,
};

const contact_info: Standard.Entities.PatientContactInfo = {
  email: 'k122@k122.com',
  homePhoneNumber: '122-122-1222',
};

const problemList: Standard.Entities.Diagnosis[] = [
  { description: 'Irritable bowel disease', code: 'K58.9' },
  { description: 'AA (alcohol abuse)', code: 'F10.10' },
  {
    description: 'Other infective spondylopathies, sacral and sacrococcygeal region',
    code: 'M46.58',
  },
];

const patientId: string = '135431';

export const maxDale: Standard.Events.PatientInContextPayload = {
  patientId,
  demographics,
  insurance,
  contact_info,
  problemList,
};
