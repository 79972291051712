import { Standard } from '@getvim/vim-connect';
import { v4 as uuid } from 'uuid';
import { encounterIdB } from '../encounters';

export const orderB: Standard.Entities.Order = {
  id: uuid(),
  type: Standard.Entities.OrderType.LAB,
  icd: [],
  cpts: [{ name: 'Mastectomy gynecomastia', code: '19300' }],
  encounterId: encounterIdB,
  createdDate: '2000-01-01',
};
