import React, { FC } from 'react';
import { Button, InputAdornment } from '@mui/material';
import { ButtonIconProps } from '../../../types';

export const ButtonIcon: FC<ButtonIconProps> = ({ id, className, disabled, icon, onClick }) => {
  return (
    <Button id={id} className={className} onClick={onClick} variant="outlined" disabled={disabled}>
      <InputAdornment position="end">{icon}</InputAdornment>
    </Button>
  );
};

ButtonIcon.displayName = 'ButtonIcon';
