import { OSSimulatorData, OSLog } from '@getvim/vim-connect-app';
import { distinctArray } from '..';

const MAXIMUM_LOGS: number = 50;

export function getUpdatedLogs(logs: OSLog[], osSimulatorData?: OSSimulatorData): OSLog[] {
  const filtersLogs = logs.filter((log: OSLog) => log);
  const updatedLogs: OSLog[] = distinctArray(
    [...filtersLogs, ...(osSimulatorData?.osLogs?.logs ?? [])],
    'id',
  );
  if (updatedLogs.length > MAXIMUM_LOGS) {
    updatedLogs.sort((a: OSLog, b: OSLog) => a.createTime - b.createTime);
    updatedLogs.splice(0, updatedLogs.length - 50);
  }
  return updatedLogs;
}
