import React, { FC, KeyboardEvent } from 'react';
import { TextField } from '@mui/material';
import { TextCountProps } from '../../../types';

export const TextCount: FC<TextCountProps> = ({
  id,
  className,
  containerClassName,
  label,
  max,
  value,
  onChange,
  disabled,
}) => {
  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    if (
      e.key === '0' ||
      e.key === 'e' ||
      e.key === '-' ||
      (max && Number.parseInt(e.key, 10) > max)
    ) {
      e.preventDefault();
    }
  };

  return (
    <div className={`text-count${className ? ` ${className}` : ''}`}>
      <div className="text-count-label">{label}:</div>
      <div className={`text-count-textbox${containerClassName ? ` ${containerClassName}` : ''}`}>
        <TextField
          id={id}
          variant="outlined"
          size="small"
          type="number"
          value={value}
          onChange={onChange}
          sx={{ display: 'none' }}
          disabled={disabled}
          InputProps={{
            inputProps: {
              min: 1,
              max: max ?? undefined,
              onKeyPress: handleKeyPress,
            },
          }}
        />
      </div>
    </div>
  );
};

TextCount.displayName = 'TextCount';
