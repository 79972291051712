import { Notifications } from '@getvim/vim-connect-communication';
import { v4 as uuid } from 'uuid';

export const predefinedPayloads: Record<string, any> = {
  "Show Notification (2 CTA's) - Eligibility": {
    event: Notifications.Types.NotificationEvent.Show,
    data: {
      notificationId: uuid(),
      options: { timeoutInMs: 10000 },
      text: uuid(),
      widgetId: 'eligibility',
      actionButtons: [
        {
          text: 'Select',
          action: 'SELECT',
          buttonType: 'tiny',
        },
        {
          text: 'See more 10 results',
          action: 'SEE_MORE_RESULT',
          buttonType: 'link',
        },
      ],
    },
  },
  'Show Notification (1 CTA) - Eligibility': {
    event: Notifications.Types.NotificationEvent.Show,
    data: {
      notificationId: uuid(),
      options: { timeoutInMs: 10000 },
      text: uuid(),
      widgetId: 'eligibility',
      actionButtons: [
        {
          text: 'Select',
          action: 'SELECT',
          buttonType: 'tiny',
        },
      ],
    },
  },
  'Show Notification - Eligibility': {
    event: Notifications.Types.NotificationEvent.Show,
    data: {
      notificationId: uuid(),
      options: { timeoutInMs: 10000 },
      text: uuid(),
      title: 'Best custom title',
      widgetId: 'eligibility',
    },
  },
  'Hide Notification - Eligibility': {
    event: Notifications.Types.NotificationEvent.Hide,
    data: {
      notificationId: uuid(),
      widgetId: 'eligibility',
    },
  },
  'Show Notification - Order Assist - 1': {
    event: Notifications.Types.NotificationEvent.Show,
    data: {
      notificationId: uuid(),
      options: { timeoutInMs: 20000 },
      text: uuid(),
      title: 'Order assist notification #1',
      widgetId: 'order-assist',
    },
  },
  'Show Notification - Order Assist - 2': {
    event: Notifications.Types.NotificationEvent.Show,
    data: {
      notificationId: uuid(),
      options: { timeoutInMs: 20000 },
      text: uuid(),
      title: 'Order assist notification #2',
      widgetId: 'order-assist',
    },
  },
  'Show Notification - CDE - 1': {
    event: Notifications.Types.NotificationEvent.Show,
    data: {
      notificationId: uuid(),
      options: { timeoutInMs: 20000 },
      text: uuid(),
      title: 'CDE notification #1',
      widgetId: 'cde',
    },
  },
  'Show Notification - CDE - 2': {
    event: Notifications.Types.NotificationEvent.Show,
    data: {
      notificationId: uuid(),
      options: { timeoutInMs: 20000 },
      text: uuid(),
      title: 'CDE notification #2',
      widgetId: 'cde',
    },
  },
};
