import { searchByName, searchByPayload } from '../services';

export const SEARCH_TYPE = {
  NAME: { key: 'NAME', func: searchByName },
  PAYLOAD: { key: 'PAYLOAD', func: searchByPayload },
};

export enum PointerEventsState {
  NONE = 'none',
  INITIAL = 'initial',
}

export enum EventType {
  STANDARD = 'standard',
  EMPTY = 'empty',
  LOADING = 'loading',
  LOCKED = 'locked',
}

export enum WarningType {
  WARNING = 'warning',
  SUCCESS = 'success',
}

export type WarningData = {
  type: WarningType;
  icon: string;
  text: string;
};
